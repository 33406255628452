import React, { Component } from 'react';
import { Table, Card, Col, Container } from 'reactstrap';
import { t } from "ttag";
import "./KalkulatorPotrosnjeView.css";

const ContactData = props => {
    return (
        <>
            <Card className="modalWin">
                <Table size="sm" borderless striped>
                    <tbody>
                        <tr>
                            <td className="modalTableLeft">{t`avt`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.avtAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`amt`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.amtAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`snaga`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.powerAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`utrosenaEnergija`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.consumedEnergyAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`porezUtrosenaEnergija`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.consumedEnergyTaxes}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`nus`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.nusAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`porezNus`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.nusTaxes}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`oiee`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.oieeAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`porezOiee`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.oieeTaxes}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`ukupnoBezPdv`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.sumWithoutTaxes}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`ukupnoPdv`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.sumTaxes}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`ukupnoSaPdv`}:</td>
                            <td className="modalTableRight">{props.izracunatoStanjePU.sumWithTaxes}</td>
                        </tr>
                    </tbody>
                </Table>
                {/* <div>
                    <Button onClick={props.closeModal} className="mt-4 mr-0 float-right btn-block" outline color="primary">{t`zatvori`}</Button>
                </div> */}
            </Card>
        </>
    )
}

class UtrosakKilovatCasovi extends Component {
    render() {
        return (
            <Container fluid className="p-0">
                <Col lg={12} xs='12' className="p-0">
                    <ContactData className="p-6" closeModal={this.props.closeModal} izracunatoStanjePU={this.props.izracunatoStanjePU}></ContactData>
                </Col>
            </Container>
        );
    }
}

export default UtrosakKilovatCasovi;