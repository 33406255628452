import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { AppContext } from '../Store';
import { Badge } from 'reactstrap';

export class SideItem extends Component {
    render() {
        let isActive = this.context.router.route.location.pathname === this.props.to;
        let inverse = this.props.inverse ? 'side-menu-item-inverse ' : 'side-menu-item ';
        let activ = isActive ? inverse.concat('side-menu-item-activ ') : inverse;
        let display = this.props.display ? 'd-block' : 'd-none'
        let className = activ.concat(display);
        let iconClass = 'lnc side-menu-item-icon '.concat(this.props.icon)
        let smallMenu = this.props.smallMenu;

        return (
            <div className={className} onClick={this.props.clickHandler}>

                <Link to={this.props.to} style={{ textDecoration: 'none' }}>

                    <div className={className}>
                        {this.props.hasBadge && <AppContext.Consumer>
                            {({ messageNumber }) => (
                                <Badge pill color='danger' className='notification-badge'>{messageNumber}</Badge>
                            )}
                        </AppContext.Consumer>}
                        <i className={iconClass}>
                        </i>
                        {(smallMenu) && <label className="side-menu-item-label">
                            {this.props.label}
                        </label>}
                    </div>
                </Link >
            </div>
        )
    }
}
SideItem.contextTypes = {
    router: PropTypes.object
};

export default SideItem
