export const SERVICE_URL = process.env.NODE_ENV === 'development' ? '/crm-services' : process.env.REACT_APP_SERVICES_URL;

export const LOGIN_URL = SERVICE_URL + "/auth/login";

export const SIGN_UP = SERVICE_URL + "/auth/sign-up";

export const FORGOT_PASSWORD = SERVICE_URL + "/auth/account-recovery";

export const NOTIFICATION = SERVICE_URL + "/notification?contentLength=100";

export const NOTIFICATION_UNREAD = SERVICE_URL + "/notification?read=false";

export const NOTIFICATION_READ = (notificationId) => SERVICE_URL + "/notification/" + notificationId + "/read";

export const NOTIFICATION_COUNT = SERVICE_URL + "/notification/unread-count";

export const ONE_NOTIFICATION = (notificationId) => SERVICE_URL + "/notification/" + notificationId;

export const RACUN_PDF_URL = (customerNumber, inoviceId) => SERVICE_URL + "/" + customerNumber + "/document/invoice/" + inoviceId;

export const UPLATNICE_PDF_URL = (customerNumber) => SERVICE_URL + "/" + customerNumber + "/document/payment-slip";

export const LAST_PAYMENT_URL = customerNumber => SERVICE_URL + "/" + customerNumber + "/payment/last";

export const UPLATE_ALL_URL = customerNumber => SERVICE_URL + "/" + customerNumber + "/payment";

export const MJERNA_MJESTA_ALL_URL = customerNumber => SERVICE_URL + "/" + customerNumber + "/measurement-point";

export const OCITANJA_LAST_URL = (customerNumber, mmeId) => SERVICE_URL + "/" + customerNumber + "/measurement-point/" + mmeId + "/measurement/last";

export const ENERGETSKA_KARTICA_URL = (customerNumber, mmeId) => SERVICE_URL + "/" + customerNumber + "/measurement-point/" + mmeId + "/measurement";

const SERVICE_USER = SERVICE_URL + "/account";

export const CUSTOMER_NUMBERS = SERVICE_USER + "/customer-numbers"

export const COUNT_CUSTOMER_NUMBER =  SERVICE_USER + "/count-customer-number"

export const SERVICE_PARTNER_URL = customerNumber => SERVICE_URL + "/" + customerNumber + "/partner";

export const CHANGE_PASSWORD_URL = SERVICE_USER + "/change-password";

export const PICTURE_URL = SERVICE_USER + "/picture";

export const CHANGE_SETTINGS_URL = SERVICE_USER + "/settings";

export const GET_APP_USER_INFO_URL = customerNumber => SERVICE_USER + "/settings/" + customerNumber;

export const ADD_CUSTOMER_NUMBER =  SERVICE_USER + "/customer-number";

export const INVOICE_URL = customerNumber => SERVICE_URL + "/" + customerNumber + "/invoice";

export const LAST_INVOICE_URL = customerNumber => SERVICE_URL + "/" + customerNumber + "/invoice/last";

export const GET_CALCULATED_CONSUMPTION = (customerNumber, mmeId, avt, amt) => SERVICE_URL + "/" + customerNumber + "/measurement-point/" + mmeId + "/calculate-consumption?avt=" + avt + "&amt=" + amt;

export const GET_CALCULATED_READING = (customerNumber, mmeId, avt, amt) => SERVICE_URL + "/" + customerNumber + "/measurement-point/" + mmeId + "/calculate-measurement?avt=" + avt + "&amt=" + amt;

export const PRIJAVA_NOVOG_STANJA_URL = (customerNumber, mmeId) => SERVICE_URL + "/" + customerNumber + "/measurement-point/" + mmeId + "/user-measurement";

export const IS_PRIJAVLJENO_NOVO_STANJE_URL = (customerNumber, mmeId) => SERVICE_URL + "/" + customerNumber + "/measurement-point/" + mmeId + "/user-measurement";

export const GET_PRIJAVLJENO_STANJE_URL = (customerNumber, mmeId) => SERVICE_URL + "/" + customerNumber + "/measurement-point/" + mmeId + "/user-measurement";

export const DELETE_PRIJAVLJENO_STANJE_URL = (customerNumber, mmeId) => SERVICE_URL + "/" + customerNumber + "/measurement-point/" + mmeId + "/user-measurement";

