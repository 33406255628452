import React, { Component } from 'react'
import { authenticationService } from '../authentication/AuthenticationService';
import { t } from "ttag";
import { Table } from 'reactstrap';
import { INVOICE_URL, RACUN_PDF_URL } from '../const';
import { Spinner } from 'reactstrap';
import { formatNumber } from '../utils/formater';
import './RacuniView.css'
import CustomButton from '../Component/CustomButton';

export class RacuniView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            data: [],
            userId: 0
        }
    }
    preuzmiRacun = (itemId, invNumber) => {
        let customerNumber = sessionStorage.getItem("naplatniBroj");
        fetch(RACUN_PDF_URL(customerNumber, itemId), {
            method: "GET",
            headers: authenticationService.authenticationHeader()
        })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = invNumber+'.pdf';
                    a.click();
                })
            })
    }

    componentWillMount = () => {
        let customerNumber = sessionStorage.getItem("naplatniBroj")
        fetch(INVOICE_URL(customerNumber), {
            method: "GET",
            headers: authenticationService.authenticationHeader()
        }).then(results => results.json()).then(data => {
            this.setState({
                data: data,
                isLoaded: true
            });
        });
    }
    render() {
        let bills = this.state.data.map(item => <TableRow key={item.id} item={item} downloadAction={() => this.preuzmiRacun(item.id, item.invNumber)} />)
        let billsBox = this.state.data.map(item => <BillBox key={item.id} item={item} downloadAction={() => this.preuzmiRacun(item.id, item.invNumber)} />)
        if (this.state.isLoaded) {
            return (
                <div className="container-fluid">
                    <div>
                        <h4 className="bill-page-title">{t`racun`}</h4><br />
                    </div>
                    <div className="row">
                        <div class="col">
                            <h5 className="bill-activ-title">{t`aktivacijaRacunaTitle`} </h5>
                        </div>
                    </div>
                    <hr />
                    <p className="bill-activ-content">{t`aktivacijaRacunaText`}</p>
                    <div className="bill-table-view">
                        <Table className="bill-table" size="sm" borderless striped>
                            <TableHeader />
                            <tbody>
                                {bills}
                            </tbody>
                        </Table>
                    </div>
                    <div className="bill-box-view">
                        {billsBox}
                    </div>
                </div>
            )
        } return (
            <Spinner className="bill-spiner" color="primary" />
        )
    }
}
export default RacuniView

const BillBox = props => (
    <div className="bill-box">
        <Table borderless size="sm" striped>
            <tbody>
                <tr>
                    <td className="text-font-size bill-td-box-left">{t`period`}</td>
                    <td className="text-font-size bill-td-box-right"> {props.item.period}</td>
                </tr>
                <tr>
                    <td className="text-font-size bill-td-box-left">{t`brojRacuna`}</td>
                    <td className="text-font-size bill-td-box-right">{props.item.invNumber}</td>
                </tr>
                <tr>
                    <td className="text-font-size  bill-td-box-left">{t`iznosKM`}</td>
                    <td className="text-font-size bill-box-value bill-td-box-right">{formatNumber(props.item.amount)}</td>
                </tr>
                <tr>
                    <td className="text-font-size bill-td-box-left">{t`preuzmiRacun`}</td>
                    <td className="text-font-size bill-td-box-right">
                        <CustomButton text={t`preuzmiRacun`} icon="lnc-download" onClick={props.downloadAction} />
                    </td>
                </tr>
            </tbody>
        </Table>
    </div>
)

const TableHeader = () => (
    <thead className="bill-thead">
        <tr>
            <th className="bill-th">{t`period`}:</th>
            <th className="bill-th">{t`brojRacuna`}:</th>
            <th className="bill-th">{t`iznosKM`}:</th>
            <th className="bill-th">{t`racun`}:</th>
        </tr>
    </thead>
);

const TableRow = props => (
    <tr>
        <td className="bill-td bill-text"> {props.item.period}</td>
        <td className="bill-td bill-text"> {props.item.invNumber}</td>
        <td className="bill-td bills-value"> {formatNumber(props.item.amount)}</td>
        <td className="bill-td ">
            <CustomButton dark={true} text={t`preuzmiRacun`} icon="lnc-download" onClick={props.downloadAction} />
        </td>
    </tr>
);
