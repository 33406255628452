import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'

export default class FeatureComponent extends Component{

    render(){

        let iconClass = 'ponuda-img ml-4 ml-md-5 pl-md-0 pl-lg-5 lnc '.concat(this.props.icon)
/**ponuda-img */
        return(
                <div className="d-flex flex-column ponuda-p w-100 mt-12">
                    <Row>
                        <div className="w-10">
                        <i class={iconClass}></i>
                        </div>
                        <Col className="w-90">
                            <h5>{this.props.title}</h5>
                            <p>{this.props.text}</p>
                        </Col>
                    </Row>
                </div>
        );
    }
}

