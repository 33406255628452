import { addLocale, useLocale } from "ttag";
import * as cookie from "./cookie";

const LOCALE_COOKIE = '__locale';

const locales = ['cir','lat']

function getLocale() {
  return cookie.get(LOCALE_COOKIE) || 'lat';
}

export function saveLocale(locale) {
  cookie.set(LOCALE_COOKIE, locale);
  useLocale(locale);
}

export function reloadLocale(){
    useLocale(currentLocale);
}

export const currentLocale = getLocale();

locales.forEach((loc) => {
  const translation = require(`../translations/${loc}.translations.json`);
  addLocale(loc, translation);
});
useLocale('lat');
