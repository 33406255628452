import React, { Component } from 'react'
import { ENERGETSKA_KARTICA_URL } from '../const';
import { authenticationService } from '../authentication/AuthenticationService';
import { Collapse, Table } from "reactstrap";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from "recharts";
import { formatDate } from "../utils/formater";
import { t } from "ttag";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

export default class EnergyCard extends Component {
    constructor(props) {
        super(props)
        var d = new Date();
        this.state = {
            fetched: false,
            chartData: [],
            startDate: d.setDate(d.getDate() - 180),
            endDate: new Date()
        }
    }
    handleChangeStart = date => {
        this.setState({
            startDate: date
        })
    }
    handleChangeEnd = date => {
        this.setState({
            endDate: date
        })
    }
    fetchData = () => {
        if (!this.state.fetched) {
            let mmeId = this.props.mmeId;
            let customerNumber = sessionStorage.getItem("naplatniBroj")
            fetch(ENERGETSKA_KARTICA_URL(customerNumber, mmeId), {
                method: 'GET',
                headers: authenticationService.authenticationHeader()
            }).then(response => {
                return response.json();
            }).then(data => {
                this.setState({
                    chartData: data,
                    fetched: true
                });
            });
        }
    }

    render() {
        var isHost = authenticationService.isHost();
        let filteredChartData = this.state.chartData.filter(data => { return new Date(data.readingDate) >= this.state.startDate && new Date(data.readingDate) <= this.state.endDate });
        let boxViewData = filteredChartData.map(item => <BoxViewData isHost={isHost} item={item} />)
        let tableViewData = filteredChartData.map(item => <TableBodyView isHost={isHost} item={item} />)
        return (
            <div className="container-fluid">
                <Collapse isOpen={this.props.collapse}>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleChangeStart}
                                dateFormat="dd. MM. yyyy."
                                className="data-picker"
                            />
                        </div>
                        <div className="col-md-6 col-12">
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={this.handleChangeEnd}
                                dateFormat="dd. MM. yyyy."
                                className="data-picker"
                            />
                        </div>
                    </div>
                    <ResponsiveContainer className="mt-2" width="100%" height={500}>
                        <BarChart data={filteredChartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="readingDate" tickFormatter={formatDate} />
                            <YAxis />
                            <Tooltip labelFormatter={formatDate} />
                            <Legend />
                            <Bar name={t`potrosnjaVt`} dataKey="avtConsumption" fill="#153a78" />
                            <Bar name={t`potrosnjaMt`} dataKey="amtConsumption" fill="#9e9b9d" />
                            {!isHost &&
                                <Bar name={t`potrosnjaVtr`} dataKey="rvtConsumption" fill="#eef221" />
                            }
                            {!isHost &&
                                <Bar name={t`potrosnjaMtr`} dataKey="rmtConsumption" fill="#21f22c" />
                            }

                        </BarChart>

                    </ResponsiveContainer>
                    <div className="d-none d-xl-block">
                        <Table striped bordered responsive size="sm">
                            <TableHeaderView isHost={isHost} />
                            <tbody>
                                {tableViewData}
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-xl-none">
                        {boxViewData}
                    </div>

                </Collapse>
            </div>
        )
    }
}
const BoxViewData = props => (
    <Table className="table-box" borderless size="sm" striped>
        <tbody>
            <tr>
                <td >{t`datum`}:</td>
                <td className="mp-bold-value">{formatDate(props.item.readingDate)}</td>
            </tr>
            <tr>
                <td >{t`aktivnoBrojilo`}:</td>
                <td className="mp-bold-value">{props.item.activeMeterNumber}</td>
            </tr>
            <tr>
                <td >{t`ocitanaVrijednostVt`} (kWh):</td>
                <td className="mp-bold-value">{props.item.avtReading}</td>
            </tr>
            <tr>
                <td >{t`potrosnjaVt`} (kWh):</td>
                <td className="mp-bold-value">{props.item.avtConsumption}</td>
            </tr>
            <tr>
                <td >{t`ocitanaVrijednostMt`} (kWh):</td>
                <td className="mp-bold-value">{props.item.amtReading}</td>
            </tr>
            <tr>
                <td >{t`potrosnjaMt`} (kWh):</td>
                <td className="mp-bold-value">{props.item.amtConsumption}</td>
            </tr>
            {!props.isHost &&
                <>
                    <tr>
                        <td >{t`reaktivnoBrojilo`}</td>
                        <td className="mp-bold-value">{props.item.reactiveMeterNumber}</td>
                    </tr>
                    <tr>
                        <td >{t`ocitanaVrijednostVtr`} (kVAr):</td>
                        <td className="mp-bold-value">{props.item.rvtReading}</td>
                    </tr>
                    <tr>
                        <td >{t`potrosnjaVtr`} (kVAr):</td>
                        <td className="mp-bold-value">{props.item.rvtConsumption}</td>
                    </tr>
                    <tr>
                        <td >{t`ocitanaVrijednostMtr`} (kVAr):</td>
                        <td className="mp-bold-value">{props.item.rmtReading}</td>
                    </tr>
                    <tr>
                        <td >{t`potrosnjaMtr`} (kVAr):</td>
                        <td className="mp-bold-value">{props.item.rmtConsumption}</td>
                    </tr>
                </>
            }
            <tr>
                <td >{t`snaga`} (kW):</td>
                <td className="mp-bold-value">{props.item.power}</td>
            </tr>
        </tbody>
    </Table>

)

const TableHeaderView = props => (
    <thead>
        <tr className="text-center">
            <th rowSpan={2}>{t`datum`}</th>
            <th rowSpan={2}>{t`aktivnoBrojilo`}</th>
            <th colSpan={4}>{t`aktivnoBrojilo`}</th>
            {!props.isHost &&
                <>
                    <th rowSpan={2}>{t`reaktivnoBrojilo`}</th>
                    <th colSpan={4}>{t`reaktivnoBrojilo`}</th>
                </>
            }
            <th rowSpan={2}>{t`snaga`} (kW):</th>
        </tr>
        <tr className="text-center">
            <th>{t`ocitanaVrijednostVt`} (kWh):</th>
            <th>{t`potrosnjaVt`} (kWh):</th>
            <th>{t`ocitanaVrijednostMt`} (kWh):</th>
            <th>{t`potrosnjaMt`} (kWh):</th>
            {!props.isHost &&
                <>
                    <th>{t`ocitanaVrijednostVtr`} (kVAr):</th>
                    <th>{t`potrosnjaVtr`} (kVAr):</th>
                    <th>{t`ocitanaVrijednostMtr`} (kVAr):</th>
                    <th>{t`potrosnjaMtr`} (kVAr):</th>
                </>
            }
        </tr>
    </thead>
)

const TableBodyView = props => (
    <tr>
        <td>{formatDate(props.item.readingDate)}</td>
        <td className="text-right">{props.item.activeMeterNumber}</td>
        <td className="text-right">{props.item.avtReading}</td>
        <td className="text-right">{props.item.avtConsumption}</td>
        <td className="text-right">{props.item.amtReading}</td>
        <td className="text-right">{props.item.amtConsumption}</td>
        {!props.isHost &&
            <>
                <td className="text-right">{props.item.reactiveMeterNumber}</td>
                <td className="text-right">{props.item.rvtReading}</td>
                <td className="text-right">{props.item.rvtConsumption}</td>
                <td className="text-right">{props.item.rmtReading}</td>
                <td className="text-right">{props.item.rmtConsumption}</td>
            </>
        }
        <td className="text-right">{props.item.power}</td>

    </tr>
)
