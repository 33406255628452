import React, { Component } from 'react';
import { Col, Row, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import UtrosakKilovatCasovi from './UtrosakKilovatCasovi'
import UtrosakOcitanje from './UtrosakOcitanje'
import { authenticationService } from '../authentication/AuthenticationService';
import { GET_CALCULATED_CONSUMPTION } from '../const';
import { GET_CALCULATED_READING } from '../const';
import "./KalkulatorPotrosnjeView.css";
import CustomButton from '../Component/CustomButton';
import { t } from "ttag";

class PrijavaStanjaForma extends Component {

    constructor(props) {
        super(props);
        this.state = {
            izracunatoStanjePU: {},
            izracunatoStanjePUO: {},
            vtUnos: 0,
            mtUnos: 0,
            selectedValue: "option1",
            classNameDisplay1: "",
            classNameDisplay2: "notDisplay",
            utrosakKilovatCasoviVisible: false,
            utrosakOcitanjeVisible: false
        }
    }

    toggleModalUtrosakKilovatCasovi = () => {
        this.setState(state => ({
            utrosakKilovatCasoviVisible: !state.utrosakKilovatCasoviVisible
        }));
    }

    toggleModalUtrosakOcitanje = () => {
        this.setState(state => ({
            utrosakOcitanjeVisible: !state.utrosakOcitanjeVisible
        }));
    }

    openModal = () => {
        this.setState({
            utrosakKilovatCasoviVisible: this.state.selectedValue === "option1" ? true : false,
            utrosakOcitanjeVisible: this.state.selectedValue === "option2" ? true : false
        })
    }

    closeModal = () => {
        this.setState({
            utrosakKilovatCasoviVisible: false,
            utrosakOcitanjeVisible: false
        })
    }

    //PRORACUN UTROŠKA
    vtPUOnChange = (event) => {
        this.setState({
            vtUnos: event.target.value
        })
    }

    mtPUOnChange = (event) => {
        this.setState({
            mtUnos: event.target.value
        })
    }

    getProracunUtroska = (mjernoMjestoId) => {
        let customerNumber = sessionStorage.getItem("naplatniBroj")
        if (this.props.mjernoMjestoId === 0) {
            return;
        }
        fetch(GET_CALCULATED_CONSUMPTION(customerNumber, mjernoMjestoId, this.state.vtUnos, this.state.mtUnos), {
            method: "GET",
            headers: authenticationService.authenticationHeader()
        })
            .then(results => results.json())
            .then(data => {
                this.setState({
                    izracunatoStanjePU: data
                })
            });
    }

    buttonPotvrdiPUClicked = () => {
        this.getProracunUtroska(this.props.mjernoMjestoId);
        this.openModal();
    }


    //PRORACUN UTROŠENOG OČITANJA
    vtPUOOnChange = (event) => {
        this.setState({
            vtUnos: event.target.value
        })
    }

    mtPUOOnChange = (event) => {
        this.setState({
            mtUnos: event.target.value
        })
    }
    getProracunUtrosenogOcitanja = (mjernoMjestoId) => {
        let customerNumber = sessionStorage.getItem("naplatniBroj")
        if (this.state.mjernoMjestoId === 0) {
            return;
        }
        fetch(GET_CALCULATED_READING(customerNumber, mjernoMjestoId, this.state.vtUnos, this.state.mtUnos), {
            method: "GET",
            headers: authenticationService.authenticationHeader()
        })
            .then(results => results.json())
            .then(data => {
                this.setState({
                    izracunatoStanjePUO: data
                })
            })
    }

    buttonPotvrdiPUOClicked = () => {
        this.getProracunUtrosenogOcitanja(this.props.mjernoMjestoId);
        this.openModal();
    }

    radioButtonSelectionChange = (event) => {
        this.setState({
            selectedValue: event.target.value,
            classNameDisplay1: event.target.value === "option2" ? "notDisplay" : "",
            classNameDisplay2: event.target.value === "option1" ? "notDisplay" : ""
            //vtUnos: event.target.value === "option2" ? 0 : this.state.vtUnos
        });
    }


    render() {
        return (
            <div>

                <div className="card whole-box">
                    <Col className="card-header header-box">
                        <h4 className="titleKalkulatorPotrosnje">{t`izborKalkulatora`}:</h4>
                        <div className="col-sm-12">
                            <div className="radio">
                                <label className="kalkPotrosnjeFont">
                                    <Input type="radio" value="option1" checked={this.state.selectedValue === "option1"} onChange={this.radioButtonSelectionChange}></Input>
                                    {t`kalkPotrosnjeKilovatCasovi`}
                                </label>
                            </div>
                            <div className="radio">
                                <label className="kalkPotrosnjeFont">
                                    <Input type="radio" value="option2" checked={this.state.selectedValue === "option2"} onChange={this.radioButtonSelectionChange}></Input>
                                    {t`kalkPotrosnjeOcitanje`}
                                </label>
                            </div>
                        </div>
                    </Col>
                    <Row className="card-body body-box">

                        <Col xs='12' md='3' className="d-flex align-items-center mr-5">
                            <div className="text-nowrap mr-3">{t`avt`} (kWh):</div>
                            <Input onChange={this.state.selectedValue==="option1"?this.vtPUOnChange:this.vtPUOOnChange} className="font-weight-bold" type="number" placeholder={t`visaTarifa`}></Input>
                        </Col>
                        <Col xs='12' md='3' className="d-flex mt-3 mt-md-0 align-items-center mr-5">
                            <div className="text-nowrap mr-3">{t`amt`} (kWh):</div>
                            <Input onChange={this.state.selectedValue==="option1"?this.mtPUOnChange:this.mtPUOOnChange} className="font-weight-bold" type="number" placeholder={t`nizaTarifa`}></Input>
                        </Col>
                        <Col xs='12' md='3' className="text-center mt-3 mt-md-1">
                            <CustomButton onClick={this.state.selectedValue==="option1"?this.buttonPotvrdiPUClicked:this.buttonPotvrdiPUOClicked} text={t`izracunaj`} icon="lnc-check"/>
                        </Col>
                    </Row>
                </div>
                
                <br></br>

                <div>
                    <Modal centered={true} isOpen={this.state.utrosakKilovatCasoviVisible}>
                        <ModalHeader toggle={this.toggleModalUtrosakKilovatCasovi} className="modal-header">{t`proracunatiUtrosak`}</ModalHeader>
                        <ModalBody className="modalBody">
                            <UtrosakKilovatCasovi className="p-0" izracunatoStanjePU={this.state.izracunatoStanjePU} closeModal={this.closeModal}></UtrosakKilovatCasovi>
                        </ModalBody>
                    </Modal>
                </div>
                <div>
                    <Modal centered={true} isOpen={this.state.utrosakOcitanjeVisible}>
                        <ModalHeader toggle={this.toggleModalUtrosakOcitanje} className="modal-header">{t`proracunatiUtrosak`}</ModalHeader>
                        <ModalBody className="modalBody">
                            <UtrosakOcitanje izracunatoStanjePUO={this.state.izracunatoStanjePUO} closeModal={this.closeModal}></UtrosakOcitanje>
                        </ModalBody>
                    </Modal>
                </div>

                <div>
                    <label>*{t`napomenaPrethodniSaldo`}</label><br></br>
                    <label>*{t`napomenaOdstupanjeDecimale`}</label><br></br>
                    <label>*{t`napomenaNezvanicnaInformacija`}</label>
                </div>

            </div>
        );
    }
}

export default PrijavaStanjaForma;