import React, { Component } from 'react';
import { Input, InputGroup, Badge } from 'reactstrap';
import { t } from 'ttag';
import logo from '../images/elektrokrajina_logo.png';
import './LoginView.css';


export default class LoginFormComponent extends Component {

    render() {
        return (
            <form>
                <img alt=''
                     src={logo}
                     className='logo mx-auto img-fluid d-block col-md-8 col-xl-12'
                />
                <hr className="horizontalLine" />
                <h5 className="w-100 mb-1 mb-md-5 mt-1 mt-md-4 text-center text-dark">{t`prijavaVelikaSlova`}:</h5>


                <div className="row col-12 nopadding d-flex justify-content-between">
                <InputGroup className="mb-1 mb-md-1 col-md-5 col-xl-12 nopadding">
                    <Input placeholder={t`korIme`} name="username" value={this.props.username} className="w-75 fieldsLine" onChange={this.props.handleChange}></Input>
                </InputGroup>

                <InputGroup className="mb-1 mb-md-1 col-md-5 col-xl-12 nopadding">
                    <Input placeholder={t`lozinka`} name="password" type="password" value={this.props.password} className="w-75 fieldsLine" onChange={this.props.handleChange}></Input>
                    <label className="w-100" style={this.props.labelStyle}>{this.props.error}</label>
                </InputGroup>

               {/*    <InputGroup className="mb-1 mb-md-1 col-md-5 col-xl-12 nopadding">
                    <Input placeholder={t`naplatniBroj`} name="customerNumber" value={this.props.customerNumber} className=" fieldsLine" onChange={this.props.handleChange}></Input>   
                    <Badge pill style={{margin: "12px", height: "50%", color:'blue', background:'lightgray', float: 'left'}} data-tip data-for="registerTipContol" >
                      i
                    </Badge>
                    <ReactTooltip id="registerTipContol" place="right" effect="solid">
                        <img src={customerNumber}/>
                    </ReactTooltip>   
             </InputGroup>*/}
                </div>

                {/* <div className="form-group mb-1 mb-md-4">
                    <label className="text-dark">{t`odaberiPismo`}</label>
                    <Input type="select" className="form-control comboBorder" value={this.props.locale} onChange={this.props.setLocale} name="locale">
                        <option value="lat">{t`latinica`}</option>
                        <option value="cir">{t`cirilica`}</option>
                    </Input>
                </div> */}
                <div className="text-center">
                <button className="btn submit-button mt-1 mb-1 mb-md-4 buttonLogin" onClick={this.props.handleSubmit}>{t`ulogujSeVelikaSlova`}</button>
                </div>
                <label className="w-100 mb-0 mb-md-2 labelForgotPassword text-center" onClick={this.props.changeResetPassword}>{t`zaboraviliSteLozinku`}</label>
                <label className="w-100 mb-0 mb-md-2 labelForgotPassword text-center" onClick={this.props.changeSingUp}>{t`registrujteSe`}</label>
            </form>
        );
    }
}
