import React, { Component } from 'react'
import { MJERNA_MJESTA_ALL_URL } from '../const';
import { authenticationService } from '../authentication/AuthenticationService';
import { t } from "ttag";
import { Spinner } from 'reactstrap';
import './MeasuringPointsView.css'
import MeasuringPointView from './MeasuringPointView';
import { Input } from 'reactstrap';

export class MeasuringPointsView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            measuringPoints: [],
            filteredMeasuringPoints: []
        }
    }
    componentWillMount() {
        let customerNumber = sessionStorage.getItem("naplatniBroj")
        fetch(MJERNA_MJESTA_ALL_URL(customerNumber), {
            method: 'GET',
            headers: authenticationService.authenticationHeader()
        }).then(response => {
            return response.json();
        }).then(data => {
            this.setState({
                measuringPoints: data,
                filteredMeasuringPoints: data,
                isLoaded: true
            })
        });
    }
    searchHandler = e => {
        let value = e.target.value;
        if (value.length > 0) {
            this.setState({
                filteredMeasuringPoints: this.state.measuringPoints.filter(item => {
                    return String(item.edNumber).includes(value) | item.activeMeterNumber.includes(value);
                })
            });
        } else {
            this.setState({
                filteredMeasuringPoints: this.state.measuringPoints
            });
        }
    }
    render() {
        let measuringPointListView = this.state.filteredMeasuringPoints.map(item => <MeasuringPointView key={item.id} item={item} />)
        var isHost = authenticationService.isHost();
        if (this.state.isLoaded) {
            return (
                <div className="container-fluid">
                    <div>
                        <h4 className="payment-page-title">{t`mjernoMjesto`}</h4><br />
                    </div>
                    {!isHost &&
                        <SearchBar searchHandler={this.searchHandler} />
                    }
                    {measuringPointListView}
                </div>
            )
        } return (
            <Spinner className="bill-spiner" color="primary" />
        )
    }
}
const SearchBar = props => (
    <div className="row measuring-point-box">
        <div className="col">
            <Input type="text" onChange={props.searchHandler} id="search" placeholder={t`searchBarPlaceholder`}></Input>
        </div>
    </div>
);


export default MeasuringPointsView
