import React, { Component } from 'react'
import { Input} from 'reactstrap';
import SideItem from './SideItem'
import logo from '../images/mini-logo.png';
import { t } from 'ttag';
import { CUSTOMER_NUMBERS  } from '../const';
import { authenticationService } from "../authentication/AuthenticationService";


export class SideMenuSmall extends Component {
    state = {
        displayMeasurmentEntryEnabled: null,
        displayCalculationEnabled: null, 
        customerNumber: '',
        customerNumbers: []
    }
    componentDidMount() {
     
        this.getCustomerNumbers();
    }

    getCustomerNumbers () {
         
        let user = authenticationService.getUser();
        fetch(CUSTOMER_NUMBERS, {
         method: 'GET',
         headers: authenticationService.authenticationHeader(),
     })  .then(response => response.json())
     .then(data => {
        console.log(data);
        this.setState({
            customerNumbers:data
        });
        var naplatniBroj = sessionStorage.getItem("naplatniBroj");
        if(naplatniBroj!=null){
            const findnaplatni = (element) => element.potNpb == naplatniBroj;
            this.setState({
             displayMeasurmentEntryEnabled:this.state.customerNumbers[this.state.customerNumbers.findIndex(findnaplatni)].measurementEntryEnabled,
             displayCalculationEnabled: this.state.customerNumbers[this.state.customerNumbers.findIndex(findnaplatni)].calculationEnabled,
             firstName: user.firstName,
             customerNumber: naplatniBroj
             });
         }
        else{
         this.setState({
             displayMeasurmentEntryEnabled: data[0].measurementEntryEnabled,
             displayCalculationEnabled: data[0].calculationEnabled,
             firstName: user.firstName,
             customerNumber: user.customerNumber
         })
        } 
     })}
        
     changeCustomerNumber = e => {

       this.setState({customerNumber : e.target.value});
       
       sessionStorage.setItem("naplatniBroj", e.target.value);

      this.refreshPage();
      
}

refreshPage(){ 
    window.location.reload(); 
}




    render() {
        let sideMenuClasses = 'side-menu-1';
        if (this.props.show) {
            sideMenuClasses = 'side-menu-1 main-open-1';
        }
        return (
            <>
                <div className={sideMenuClasses} >

                    <img onClick={this.props.sideMenuToggleClickHandler}
                        alt=''
                        src={logo}
                        className='logo-picture-mini mx-auto img-fluid d-block'
                    />
                    <hr className="hr" />
                    <div className="side-content">
                        <div id="first-half">
                            <SideItem  clickHandler={this.props.clickHandler} display={true} icon="lnc-home" to="/" label={t`pocetna`} />
                            <SideItem  clickHandler={this.props.clickHandler} display={true} icon="lnc-location" to="/mjerna-mjesta/" label={t`mjernoMjesto`}></SideItem>
                            <SideItem  clickHandler={this.props.clickHandler} display={true} icon="lnc-bill" to="/racuni/" label={t`racun`}></SideItem>
                            <SideItem  clickHandler={this.props.clickHandler} display={true} icon="lnc-wallet" to="/uplate/" label={t`uplate`}></SideItem>
                            <SideItem  clickHandler={this.props.clickHandler} display={this.state.displayMeasurmentEntryEnabled} icon="lnc-edit" to="/prijava-stanja/" label={t`prijavaStanja`}></SideItem>
                            <SideItem  clickHandler={this.props.clickHandler} display={this.state.displayCalculationEnabled} icon="lnc-calculations" to="/kalkulator-potrosnje/" label={t`kalkulatorPotrosnje`}></SideItem>
                        </div>
                        <div id="second-half">
                            <div id="second-half-child">
                            {this.state.customerNumbers.length > 1 ?
                                <Input style={{ background: 'transparent', color: 'white', widht: "7px", border: "0px transparent" }} className="mb-3" type="select" onChange={this.changeCustomerNumber} value={this.state.customerNumber} name="select">
                                                    {this.state.customerNumbers.map( cusNum => 
                                                    <option style={{ background: 'gray', color: 'white' }}  key={cusNum.potNpb }>{cusNum.potNpb }</option>)}

                                </Input> : ""} 
                                <SideItem hasBadge={true} clickHandler={this.props.clickHandler} inverse={true} display={true} icon="lnc-bell" to="/obavjestenja" label={t`obavjestenja`}></SideItem>
                                <SideItem  clickHandler={this.props.clickHandler} inverse={true} display={true} icon="lnc-cog" to="/podesavanja" label={t`podesavanja`}></SideItem>
                                <SideItem  clickHandler={this.props.clickHandler} inverse={true} display={true} icon="lnc-undo" to="/login"></SideItem>
                            </div>
                        </div>
                        <div id="div-1">
                            <div id="div-2">
                                <div id="div-3"></div>
                            </div>
                        </div>
                        <div className="side-bottom"></div>
                    </div>
                </div>
            </>
        )
    }
}

export default SideMenuSmall
