import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip';
import SwitchButton from './SwitchButton'
import { Form, FormGroup, Modal, ModalHeader, ModalBody, Label, Input, Spinner, Badge } from 'reactstrap';
import { Alert } from 'reactstrap';
import { authenticationService } from "../authentication/AuthenticationService";
import { podesavanjaService } from "./PodesavanjaService.js";
import { GET_APP_USER_INFO_URL } from '../const';
import { t } from 'ttag';
import "./PodesavanjaView.css";
import CustomButton from '../Component/CustomButton';
import { saveLocale } from '../localization/i18n';
import { AppContext } from '../Store';
import customerNumber from '../images/CRMNaplatniBroj.png';

export class SettingsView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedFile: false,
            isLoaded: false,
            passwordModalOpen: false,
            requestModalOpen: false,
            invoiceOnEmailEnabled: false,
            notificationOnEmailEnabled: false,
            language: 1,
            modalMessageVisibile: false,
            messageVisibile: false,
            backdrop: true,
            customerNumber: ''

        }
    }
    fileSelectedHandler = e => {
        this.setState({
            selectedFile: true,
            selectedFileURL: URL.createObjectURL(e.target.files[0]),
            selectedFileByte: e.target.files[0]
        })
    }
    toggleModal = () => {
        this.setState(state => ({
            passwordModalOpen: !state.passwordModalOpen,
            modalMessageVisibile: false
        }));
    }

    toggleModalRequest = () => {
        this.setState(state => ({
            requestModalOpen: !state.requestModalOpen,
            modalMessageVisibile: false
        }));
    }

    onDismiss = () => {
        this.setState({ messageVisibile: false });
    }
    onDismissModal = () => {
        this.setState({ modalMessageVisibile: false });
    }
    billEmailswitchHandler = () => {
        this.setState((state) => ({
            invoiceOnEmailEnabled: !state.invoiceOnEmailEnabled
        }));
    }
    notificactionEmailSwitchHandler = () => {
        this.setState((state) => ({
            notificationOnEmailEnabled: !state.notificationOnEmailEnabled
        }));
    }

    changeLanguage = e => {
        this.setState({
            language: e.target.value
        });
        if (e.target.value === 2) {
            saveLocale("lat")
        } else {
            saveLocale("cir")
        }
    }

    handleChangePasswordModal = e => {
        let { name, value } = e.target
        switch (name) {
            case "oldPassword":
                this.setState({
                    oldPassword: value
                });
                break;
            case "newPassword":
                this.setState({
                    newPassword: value
                });
                break;
            case "repPassword":
                this.setState({
                    repPassword: value
                });
                break;
            default:
        }
    }
    handleAdditionalCustomerNumber = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
      }

    fileSelectedHandler = e => {
        this.setState({
            selectedFile: true,
            selectedFileURL: URL.createObjectURL(e.target.files[0]),
            selectedFileByte: e.target.files[0]
        })
    }
    submitModalHandler = () => {
        let { oldPassword, newPassword, repPassword } = this.state;
        podesavanjaService.changePassword(oldPassword, newPassword, repPassword).then(data => {
            if (data.id) {
                authenticationService.setAuthData(data, newPassword);
                this.setState({
                    modalMessageVisibile: true,
                    messageBody: t`porukaSacuvano`,
                    passwordModalOpen: false
                })
            } else {
                this.setState({
                    modalMessageVisibile: true,
                    messageBody: data.message
                })
            }
        })
    }

    submitModalReguestHandler = e => {
        const {customerNumber} = this.state
          podesavanjaService.sendCustomerNumber(customerNumber).then(status => {
            if(!status.success){
              //console.log(status.error);
              this.setState({
                messageBodyRequest: status.error,
                modalMessageVisibile: true
              })
            } else {
              console.log(status.error);
              this.setState({
                messageBodyRequest: t`zahtjevPoslat`,
                modalMessageVisibile: true
              })
            }
          })
    }

    getAppUserInfo = () => {
        let customerNumber = sessionStorage.getItem("naplatniBroj")
        fetch(GET_APP_USER_INFO_URL(customerNumber), {
            method: 'GET',
            headers: authenticationService.authenticationHeader()
        })
            .then(results => {
                return results.json();
            })
            .then(data => {
                this.setState({
                    displayInvoiceOnEmailButton: data.invoiceOnEmailChangeable,
                    invoiceOnEmailEnabled: data.invoiceOnEmailEnabled,
                    language: data.languageId
                });
            })
        if (this.state.displayInvoiceOnEmailButton === false) {
            this.setState({
                visibleMessage: false,
                message: t`porukaNijeSacuvano`
            });
        }
    }
    submitHandler = () => {
        let { selectedFileByte,  customerNumber, invoiceOnEmailEnabled, notificationOnEmailEnabled, language } = this.state;
        podesavanjaService.changeSettings(
            selectedFileByte,
            customerNumber = sessionStorage.getItem("naplatniBroj"),
            invoiceOnEmailEnabled,
            notificationOnEmailEnabled,
            language
        ).then(data => {
            if (data.id) {
                setTimeout(() => {
                    this.setState({
                        messageVisibile: true,
                        message: t`porukaSacuvano`
                    });
                }, 100);
                this.setState({
                    messageVisibile: false,
                    message: t`porukaSacuvano`
                });
            }
           // this.getAppUserInfo();

        });
    }

    componentDidMount() {
        let user = authenticationService.getUser();
        this.setState({
            invoiceOnEmailEnabled: user.invoiceOnEmailEnabled,
            notificationOnEmailEnabled: user.notificationOnEmailEnabled,
            language: user.language.languageId,
            displayInvoiceOnEmailButton: user.invoiceOnEmailChangeable,
            isLoaded: true
        })
        this.getAppUserInfo();

    }



    render() {
        if (this.state.isLoaded) {
            return (
                <>
                    <div className="container-fluid">
                        <label className="settings-title-label">{t`podesavanja`}:</label>
                        <AppContext.Consumer>
                            {({ picture, setPicture }) => (
                                <div className="row">
                                    <div className="col-12 col-md-4 col-sm-4 col-lg-3 image-container">
                                        {this.state.selectedFile && <img onLoad={this.onImgLoad} alt="" src={this.state.selectedFileURL} className="mx-auto img-responsive img-fluid d-block " />}
                                        {!this.state.selectedFile && <img alt="" src={picture} className="img-fluid img-responsive img-fluid d-block" ></img>}
                                        <button className="btn-upload" onClick={() => this.fileInput.click()} ><i className="lnc lnc-upload2 icon-upload"></i></button>
                                    </div>
                                    <input style={{ display: 'none' }} type="file" onChange={this.fileSelectedHandler} ref={fileInput => this.fileInput = fileInput}></input>
                                    <div className="col-12 col-lg-6 col-md-8 col-sm-8 col-xl-5">
                                        <Alert color="warning" isOpen={this.state.messageVisibile} toggle={this.onDismiss}>
                                            {this.state.message}
                                        </Alert>
                                        <h6 className="note-label" style={{ display: (this.state.displayInvoiceOnEmailButton ? 'none' : '') }}>
                                            <label>{t`napomenaPodesavanja`}</label>
                                        </h6>
                                        <Form>
                                            <FormGroup className={this.state.displayInvoiceOnEmailButton ? '' : "disabled"}>
                                                <SwitchButton checked={this.state.invoiceOnEmailEnabled} switchAction={this.billEmailswitchHandler} label={t`racunNaEmail`} />
                                            </FormGroup>
                                            <FormGroup>
                                                <SwitchButton checked={this.state.notificationOnEmailEnabled} switchAction={this.notificactionEmailSwitchHandler} label={t`obavjestenjeNaEmail`} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="settings-input-label" for="exampleSelect">{t`odaberiPismo`}</Label>
                                                <Input className="settings-input-combobox" type="select" onChange={this.changeLanguage} value={this.state.language} name="select" id="exampleSelect">
                                                    <option value={2}>{t`latinica`}</option>
                                                    <option value={1}>{t`cirilica`}</option>
                                                </Input>
                                            </FormGroup>
                                        </Form>
                                        <div className="row col-12 mb-3 settings-button">
                                           
                                            <div className="col-12 mb-7">
                                                <CustomButton
                                                    style={{ float: 'right' }}
                                                    className="settings-custom-button"
                                                    text={t`sacuvaj`} icon="lnc-save"
                                                    onClick={() => {
                                                        if (this.state.selectedFile) {
                                                            setPicture(this.state.selectedFileURL)
                                                        }
                                                        this.submitHandler()
                                                    }} />
                                            </div>   
                                        </div>
                                            <div className="row col-12 pt-30">
                                                <CustomButton style={{ float: 'left' }} className="settings-custom-button" text={t`promjenaLozinke`} icon="lnc-edit" onClick={this.toggleModal} />
                                            </div>

                                            <div className="row  col-12  mt-3">
                                                <CustomButton style={{ float: 'left' }} className="settings-custom-button" text={t`zahtjevNaplatni`} icon="lnc-plus" onClick={this.toggleModalRequest} />
                                            </div>
                                    </div>
                                </div>
                            )}
                        </AppContext.Consumer>
                        <Modal centered={true} isOpen={this.state.passwordModalOpen} backdrop={this.state.backdrop}>
                            <ModalHeader toggle={this.toggleModal} className="modal-header">{t`promjenaLozinke`}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label className="settings-modal-input-label" for="oldPassword" >{t`staraSifra`}:</Label>
                                        <Input className="settings-input-field" type="password" onChange={this.handleChangePasswordModal} name="oldPassword" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="settings-modal-input-label" for="newPassword" >{t`novaSifra`}:</Label>
                                        <Input className="settings-input-field" type="password" name="newPassword" onChange={this.handleChangePasswordModal} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="settings-modal-input-label" for="repPassword" >{t`ponovljenaSifra`}:</Label>
                                        <Input className="settings-input-field" type="password" name="repPassword" onChange={this.handleChangePasswordModal} />
                                    </FormGroup>
                                    <Alert color="warning" isOpen={this.state.modalMessageVisibile} toggle={this.onDismissModal}>
                                        {this.state.messageBody}
                                    </Alert>
                                    <div className="settings-modal-button">
                                        <CustomButton className="mb-2 custom-button-modal" text={t`sacuvaj`} icon="lnc-save" onClick={this.submitModalHandler}></CustomButton>
                                    </div>
                                </Form>
                            </ModalBody>
                        </Modal>

                        <Modal centered={true} isOpen={this.state.requestModalOpen} backdrop={this.state.backdrop}>
                            <ModalHeader toggle={this.toggleModalRequest} className="modal-header">{'Pošalji zahtjev za dodatni naplatni broj'}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label className="settings-modal-input-label" for="customerNumber" >{'Dodatni naplatni broj:'}</Label>
                                      <div class="row"> 
                                      <div class="col-11">
                                          <Input className="settings-input-field" type="text" name="customerNumber"  onChange={this.handleAdditionalCustomerNumber} />
                                      </div>
                                      <div class="col-1 pl-0" >
                                        <Badge pill style={{margin: " 0 3px 0 0", color:'blue', background:'lightgray', float: 'left'}} data-tip data-for="registerTipContol" >
                                            i
                                        </Badge>
                                        <ReactTooltip id="registerTipContol" place="right" effect="solid">
                                                <img src={customerNumber}/>
                                        </ReactTooltip>
                                        </div>
                                        </div> 
                                    </FormGroup>
                                    <Alert color="warning" isOpen={this.state.modalMessageVisibile} toggle={this.onDismissModal}>
                                        {this.state.messageBodyRequest}
                                    </Alert>
                                    <div className="settings-modal-button">
                                        <CustomButton className="mb-2 custom-button-modal" text={t`sacuvaj`} icon="lnc-save" onClick={this.submitModalReguestHandler}></CustomButton>
                                    </div>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </div>
                </>
            )
        } return (
            <Spinner className="bill-spiner" color="primary" />
        )
    }
}

export default SettingsView
