import { authenticationService } from "../authentication/AuthenticationService";
import { CHANGE_PASSWORD_URL, PICTURE_URL, CHANGE_SETTINGS_URL, ADD_CUSTOMER_NUMBER } from '../const';
export const podesavanjaService = {
    changePassword,
    getPicture,
    changeSettings,
    sendCustomerNumber
};

class Status {
    success = false
    error = ''
    constructor(success, error) {
      this.success = success
      this.error = error
    }
  }

function changePassword(oldPassword, newPassword, repPassword) {
    return fetch(CHANGE_PASSWORD_URL, {
        method: 'PUT',
        headers: authenticationService.authenticationHeader(),
        body: JSON.stringify({
            old: oldPassword,
            firstNew: newPassword,
            repeatedNew: repPassword,
        })
    }).then(response => {
        return response.json();
    })

}
function sendCustomerNumber(additionalCustomerNumber) {
return fetch(ADD_CUSTOMER_NUMBER, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      customerNumber: additionalCustomerNumber
    })
  }) .then(response => {
      if (response.status === 200) {   
        return  new Status(true,'');
      } else {   
    return response
    .json()
    .then(data => {
      return new Status(false, data.message);
    })      
      }
    })
    .catch(error => {
      return new Status(false, error.message);
    })
}
function getPicture() {
    return fetch(PICTURE_URL, {
        method: 'GET',
        headers: authenticationService.authenticationHeader(),
    }).then(response => {
        return response.blob();
    })
}
function changeSettings(picture, customerNumber, indRacun, indEmail, lang) {
    let user = authenticationService.getUser();
    user.invoiceOnEmailEnabled = indRacun;
    user.notificationOnEmailEnabled = indEmail;
    user.language.languageId = lang;
    if (picture) {
        fetch(PICTURE_URL, {
            method: 'PUT',
            headers: authenticationService.authenticationHeader(),
            body: picture
        })
    }

    console.log("user potNpb " + customerNumber +"user racun " + indRacun  +" user email " + indEmail    + " user lang "+ lang);

    return fetch(CHANGE_SETTINGS_URL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          customerNumber: customerNumber,
          invoiceOnEmailEnabled: indRacun,
          notificationOnEmailEnabled: indEmail,
          languageId: lang
          })
    }).then(response => {     
        sessionStorage.setItem("user", JSON.stringify(user));
        return response.json();
    })
}