import React from 'react';
import { authenticationService } from '../authentication/AuthenticationService';
import { Row, Col } from 'reactstrap';
import { t } from 'ttag';
import { saveLocale } from '../localization/i18n';
import './LoginView.css';
import FeatureComponent from './FeatureComponent';
import LoginFormComponent from './LoginFormComponent';
import ResetPassword from './ResetPassword';
import SignUpView from './SignUpView';

const labelStyle = {
  color: 'red'
}

export default class LoginView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      error: '',
      locale: 'cir',
      dialog:'login',
    }
    this.showLoginDialog = this.showLoginDialog.bind(this)
  }

  setLocale = () => {
    // ev.preventDefault()
    // let locale = ev.target.value
    saveLocale(this.state.locale)
    // this.setState({ locale: locale })
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleEmail = e => {
    const { value } = e.target
    this.setState({ email: value })
  }

  componentWillMount() {
    // this.setState({
    //   locale: 'cir'
    // })
    this.setLocale();
  }

  componentDidMount() {
    authenticationService.logout();
    this.setLocale();
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({
      error: ''
    })
    const { username, password} = this.state
    if (!(username && password )) {
      return
    }
    authenticationService.login(username, password).then(loginStatus => {
      if (loginStatus.success) {
        const { from } = this.props.location.state || {
          from: { pathname: '/' }
        }
        this.props.history.push(from)
      } else {
        this.setState({
          error: loginStatus.error
        })
      }
    })
  }

  showLoginDialog = ()=>{
    this.setState({dialog:"login"})
  }
  showSingUpDialog = () => {
    this.setState({dialog:"signUp"})
  }

  showResetPasswordDialog = () => {
    this.setState({dialog:"resetPassword"})
  }


  render() {
    const { username, password, customerNumber } = this.state
    return (
      <div className='wrapper'>
        <div className='container d-flex align-items-center'>
          <Row className='align-items-center justify-content-center'>

            <Col xs='10' xl='4' className="sticky-top order-1 order-xl-1 mt-xs-2 mt-4 mb-1 mb-md-3 col-sm-8 col-md-10 col-xl-4">
              <div className='form-container p-3 p-md-4'>
                {this.state.dialog=='login' ? (
                  <LoginFormComponent
                    //clickEvent={()=>this.handleOnClick("visible")}
                    username={username}
                    password={password}
                    labelStyle={labelStyle}
                    error={this.state.error}
                    locale={this.state.locale}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    // setLocale={this.setLocale}
                    changeSingUp={this.showSingUpDialog}
                    changeResetPassword={this.showResetPasswordDialog}
                  />
            
                ) : (
                  this.state.dialog=='signUp' ?
                          <SignUpView
                            showLoginDialog={this.showLoginDialog}
                          />  :
                          <ResetPassword
                            showLoginDialog={this.showLoginDialog}
                          />
                          
                  )}
              </div>
            </Col>

            <Col xs='9' xl='8' className='order-2 order-xl-2 mt-3 mb-4 col-sm-8 col-md-10 col-xl-8'>
              <Row className="mt-1 mt-xl-4">
                <FeatureComponent
                  icon="lnc-user3"
                  title={t`stanjeKupca`}
                  text={t`stanjeKupcaOpis`}
                />
                <FeatureComponent
                  icon="lnc-location"
                  title={t`mjernaMjesta`}
                  text={t`mjernaMjestaOpis`}
                />
                <FeatureComponent
                  icon="lnc-search"
                  title={t`racuniUplate`}
                  text={t`racuniUplateOpis`}
                />
                <FeatureComponent
                  icon="lnc-file-text"
                  title={t`obavjestenja`}
                  text={t`obavjestenjaOpis`}
                />
                <FeatureComponent
                  icon="lnc-calculations"
                  title={t`kalkulatorPotrosnje`}
                  text={t`kalkulatorPotrosnjeOpis`}
                />
                <FeatureComponent
                  icon="lnc-dashboard"
                  title={t`stanjeBrojila`}
                  text={t`stanjeBrojilaOpis`}
                />
              </Row>
            </Col>

          </Row>
        </div>
      </div>
    )
  }
}
