import React, { Component } from 'react'


export class SwitchButton extends Component {

    render() {
        return (
            <div className="d-flex  switch-button justify-content-between mt-2">
                <label className="settings-input-label" >{this.props.label}</label>
                <label className="settings-switch">
                    <input type="checkbox" checked={this.props.checked} onChange={this.props.switchAction} />
                    <span className="settings-slider settings-round"></span>
                </label>
            </div>
        )
    }
}

export default SwitchButton
