import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ONE_NOTIFICATION, NOTIFICATION_READ } from '../const';
import { formatDateTime } from '../utils/formater';
import { authenticationService } from '../authentication/AuthenticationService';
import { Link } from 'react-router-dom'

export default class NotificationCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      notification: [],
      flag: ''
    }
  }
  componentDidMount = () => {
    const { id } = this.props.match.params
    fetch(ONE_NOTIFICATION(id), {
      method: 'GET',
      headers: authenticationService.authenticationHeader()
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        this.setState({
          notification: data
        })
      })
      .catch(error => {
      })
      this.oneNotificationRead();
  }

  oneNotificationRead = e => {
    const { id } = this.props.match.params
    fetch(NOTIFICATION_READ(id), {
      method: 'PUT',
      headers: authenticationService.authenticationHeader()
    })
      .then(response => {
        return response.json()
      })
      .then(e => {
        this.setState({
          flag: e
        })
      })
      .catch(error => {
      })
  }
  render () {
    const { ...data } = this.state.notification

    return (
      <Container fluid className='one-notification'>
         <Row className='first-row'>
            <Col><div lg={6} sm={4}><label className='item-date-card'>
                  {formatDateTime(data.notificationSysdate)}
                </label>
            </div>
            </Col>
            <Col>
            <div className='div-button'>
              <Link to={`/obavjestenja`}>
                  <i className='lnc lnc-undo lnc-icon'></i>
                </Link>
            </div> 
            </Col>       
        </Row>
        <Row className='notification-header'>
          <Col ><h5 className='item-header'>{data.header}</h5></Col>
        </Row>
        <Row>
          <div className='item-content-dialog'>{data.content}</div>
        </Row>
      </Container>
    )
  }
}
