import React, { Component } from 'react';
import { Table, Card, Col, Container } from 'reactstrap';
import { t } from "ttag";

const ContactData = props => {
    return (
        <>
            <Card className="modalWin">
                <Table size="sm" borderless striped>
                    <tbody>
                        <tr>
                            <td className="modalTableLeft">{t`avt`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.avtAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`amt`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.amtAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`snaga`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.powerAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`utrosenaEnergija`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.consumedEnergyAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`porezUtrosenaEnergija`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.consumedEnergyTaxes}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`nus`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.nusAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`porezNus`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.nusTaxes}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`oiee`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.oieeAmount}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`porezOiee`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.oieeTaxes}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`ukupnoBezPdv`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.sumWithoutTaxes}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`ukupnoPdv`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.sumTaxes}</td>
                        </tr>
                        <tr>
                            <td className="modalTableLeft">{t`ukupnoSaPdv`}</td>
                            <td className="modalTableRight">{props.izracunatoStanjePUO.sumWithTaxes}</td>
                        </tr>
                    </tbody>
                </Table>
                {/* <div>
                    <Button onClick={props.closeModal} className="mt-4 mr-0 float-right btn-block" outline color="primary">{t`zatvori`}</Button>
                </div> */}
            </Card>
        </>
    )
}

class UtrosakKilovatCasovi extends Component {
    render() {
        return (
            <Container fluid className="p-0">
                <Col lg={12} xs='12' className="p-0">
                    <ContactData className="p-6" closeModal={this.props.closeModal} izracunatoStanjePUO={this.props.izracunatoStanjePUO}></ContactData>
                </Col>
            </Container>
        );
    }
}

export default UtrosakKilovatCasovi;