import React, { Component } from 'react'

import CustomButton from '../Component/CustomButton'
import { t } from "ttag";
import { Table } from 'reactstrap';
import EnergyCard from './EnergyCard';

export class MeasuringPointView extends Component {
    constructor(props) {
        super(props)
        this.child = React.createRef();
        this.state = {
            collapse: false
        }
    }

    collapseECard = () => {
        this.child.current.fetchData();
        this.setState(prev => {
            return { collapse: !prev.collapse }
        });
    }

    render() {
        var headerData = {
            turnedOff: this.props.item.turnedOff,
            validContract: this.props.item.validContract
        }
        return (
            <div className="row measuring-point-box">
                <div className="col-md-6 col-sm-12">
                    <TableHeader data={headerData} />
                </div>
                <div className="col-md-6 col-sm-12">
                    <CustomButton style={{ float: 'right' }} className="mp-custom-button" text={t`energKart`} icon="lnc-chart" onClick={this.collapseECard} />
                </div>
                <Table className="mp-table-border-top"borderless size="sm" striped>
                    <tbody>
                        <tr>
                            <td className="text-font-size mp-title-label">{t`edBroj`}:</td>
                            <td className=" text-font-size mp-bold-value"> {this.props.item.edNumber}</td>
                        </tr>
                        <tr>
                            <td className="text-font-size mp-title-label">{t`katTarGrupa`}:</td>
                            <td className="text-font-size mp-bold-value">{this.props.item.tariffGroup}</td>
                        </tr>
                        <tr>
                            <td className="text-font-size mp-title-label">{t`odbPrikSnag`} (kW):</td>
                            <td className="text-font-size mp-bold-value">{this.props.item.power}</td>
                        </tr>
                        <tr>
                            <td className="text-font-size mp-title-label">{t`brojilo`}:</td>
                            <td className="text-font-size mp-bold-value">{this.props.item.activeMeterNumber}</td>
                        </tr>
                        <tr>
                            <td className="text-font-size mp-title-label">{t`adresa`}:</td>
                            <td className="text-font-size mp-bold-value">{this.props.item.address}</td>
                        </tr>
                        <tr>
                            <td className="text-font-size mp-title-label">{t`namjena`}:</td>
                            <td className="text-font-size mp-bold-value">{this.props.item.activity}</td>
                        </tr>
                    </tbody>
                </Table>
                <EnergyCard ref={this.child} collapse={this.state.collapse} mmeId={this.props.item.id}></EnergyCard>
            </div>
        )
    }
}

const TableHeader = props => (
    <div className="row">
        <div className="inline">
            {props.turnedOff ?
                (
                    <div className="inline red">
                        <i className="header-icon  lnc lnc-x"></i>
                        <label>{t`mmInactive`.toUpperCase()}</label>
                    </div>
                ) : (
                    <div className="inline green">
                        <i className="header-icon lnc lnc-check"></i>
                        <label>{t`mmActive`.toUpperCase()}</label>
                    </div>
                )}
        </div>
        <label className="backslash">/</label>
        <div className="inline">
            {props.validContract ?
                (
                    <div className="inline red">
                        <i className="header-icon lnc lnc-x"></i>
                        <label>{t`nemaUgovorSnabd`.toUpperCase()}</label>
                    </div>
                ) : (
                    <div className="inline green">
                        <i className="header-icon  lnc lnc-check"></i>
                        <label>{t`imaUgovorSnabd`.toUpperCase()}</label>
                    </div>
                )}
        </div>
    </div>
)

export default MeasuringPointView
