import React, { useState }  from 'react';
import ReactTooltip from 'react-tooltip';
import {  Form,  Badge } from 'reactstrap';
import { t } from 'ttag';
import { Col, Row } from 'reactstrap';
import controlNumber from '../images/CRMKontrolniBroj.png';
import customerNumber from '../images/CRMNaplatniBroj.png';
import { authenticationService } from '../authentication/AuthenticationService';
//const [symbolsArr] = useState(["e", "E", "+", "-", "."]);

export default class SignUpView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      address: '',
      phone: '',
      email: '',
      customerNumber: '',
      controlNumber: '',
      messageSignUp: '',
      visibleSignUp: true,
      showMessage: false,
      actionSuccess: false
    }
    this.okAction = this.okAction.bind(this)
    this.handleBtnNazad = this.handleBtnNazad.bind(this)
  }
  handleChange = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
  }
  handleChangePhone(evt) {
    const phone = (evt.target.validity.valid) ? evt.target.value : this.state.phone;
    
    this.setState({ phone });
  }
  handleChangeCustomerNumber(evt) {
    const customerNumber = (evt.target.validity.valid) ? evt.target.value : this.state.customerNumber;
    
    this.setState({ customerNumber });
  }
  handleChangeControlNumber(evt) {
    const controlNumber = (evt.target.validity.valid) ? evt.target.value : this.state.controlNumber;
    
    this.setState({ controlNumber });
  }
  okAction = e => {
    this.setState({ showMessage: true })
    this.newAcount(); 
  }
  handleBtnNazad = e => {
    if(this.state.actionSuccess){
      this.props.showLoginDialog();
    }else{
      this.setState({ showMessage: false })
    }
  }
  newAcount = e => {
    const {firstName, lastName, address, phone, email, customerNumber, controlNumber} = this.state
    this.setState({
      messageSignUp: ''
    })
    authenticationService.signUp(firstName, lastName, address, phone, email, customerNumber, controlNumber).then(userStatus => {
      if(!userStatus.success){
        console.log(userStatus.error);
        this.setState({
          messageSignUp: userStatus.error,
          actionSuccess: false
        })
      } else {
        console.log(userStatus.error);
        this.setState({
          messageSignUp: t`dostavljeniKredencijali`,
          actionSuccess : true
        })
      }
    })
  }

 
 

  render(){

    let enabled = false;
    if(!this.state.firstName || !this.state.lastName || !this.state.address || !this.state.phone || !this.state.email || !this.state.customerNumber || !this.state.controlNumber ) {
      enabled = true;
    }
    return(
      <div>
      {this.state.showMessage ? (
        <div>
          <h1 className='text text-dark'>
           {this.state.messageSignUp}
             </h1>
         <button
           className='btn submit-button'
           onClick={this.handleBtnNazad}
         >{t`nazad`}</button>
       </div>
     ) : (
       <Form 
      >
      <h1 style={{color:'black', fontSize:'24px'}}>{t`registracijaNovogNaloga`}</h1>
             <div style={{marginBottom: "15px", marginRight: "0px"}}>
             <span style={{color: "red"}}>{this.state.firstName == "" ? "*" : ""}</span>
                <input
                    style={{width: "90%"}}
                    className="fieldsLine"  
                    placeholder={t`ime`}
                    type="text"
                    name='firstName'
                    value = {this.state.firstName}
                    onChange={this.handleChange}
               />
            </div>
            <div style={{marginBottom: "15px"}}>
              <span style={{color: "red"}}>{this.state.lastName == "" ? "*" : ""}</span>
              <input
                    style={{width: "90%"}}
                    className="fieldsLine"
                    placeholder={t`prezime`}
                    type="text"
                    name='lastName'
                    value = {this.state.lastName}
                    onChange={this.handleChange}
               />
             </div>
             <div style={{marginBottom: "15px"}}>
                <span style={{color: "red"}}>{this.state.address == "" ? "*" : ""}</span>
                <input
                    style={{width: "90%"}}
                    className="fieldsLine"
                    placeholder={t`adresa`}
                    type="text"
                    name='address'
                    value={this.state.address}
                    onChange={this.handleChange}               
                />
            </div>
            <div style={{marginBottom: "30px"}}>
               <span style={{color: "red"}}>{this.state.phone == "" ? "*" : ""}</span>
               <input
                    style={{width: "90%"}}
                    className="fieldsLine"
                    placeholder={t`brojTelefona`}
                    type="text"
                    pattern="[0-9]*"
                    name='phone'
                    value={this.state.phone}
                    onInput={this.handleChangePhone.bind(this)} 
                />
             </div>
             <div style={{marginBottom: "15px"}}>
               <span style={{color: "red"}}>{this.state.email == "" ? "*" : ""}</span>
               <input
                    style={{width: "90%"}}
                    className="fieldsLine"
                    placeholder={t`email`}
                    type="text"
                    name='email'
                    value={this.state.email}
                    onChange={this.handleChange}
                />
             </div>
             <div style={{marginBottom: "15px"}}> 
                <span style={{color: "red"}}>{this.state.customerNumber == "" ? "*" : ""}</span>
                <input
                    style={{width: "90%"}}
                    className="fieldsLine"
                    placeholder={t`naplatniBroj`}
                    type="text"
                    pattern="[0-9]*" 
                    value={this.state.customerNumber} 
                    name='customerNumber'
                    onInput={this.handleChangeCustomerNumber.bind(this)} 
                 />
                 <Badge pill style={{margin: "3px", color:'blue', background:'lightgray'}} data-tip data-for="registerTipCustomer" >
                    i
                  </Badge>
                 <ReactTooltip style={{width: "20px", height: "50px"}} id="registerTipCustomer" place="right" effect="solid">
                        <img src={customerNumber} />
                  </ReactTooltip>
            </div>
             <div style={{marginBottom: "15px"}}>
                <span style={{color: "red"}}>{this.state.controlNumber == "" ? "*" : ""}</span> 
                <input
                    style={{width: "90%"}}
                    className="fieldsLine"
                    placeholder={t`kontrolniBroj`}
                    type="text"
                    pattern="[0-9]*"
                    name='controlNumber'
                    value={this.state.controlNumber}
                    onInput={this.handleChangeControlNumber.bind(this)} 
                    />
                  <Badge pill style={{margin: "3px", color:'blue', background:'lightgray'}} data-tip data-for="registerTipContol" >
                    i
                  </Badge>
                  <ReactTooltip id="registerTipContol" place="right" effect="solid">
                        <img src={controlNumber}/>
                  </ReactTooltip>
              </div>  
                 <Row className='p-xs-0 p-xl-12 mt-2 mr-1'>
                    <Col>
                      <button
                        className='btn submit-button'
                        onClick={this.props.showLoginDialog}
                      >{t`nazad`}</button>
                    </Col>
                    <Col>
                      <button 
                          className='btn submit-button  float-right' 
                          onClick={this.okAction} 
                          disabled= {enabled}>
                          {t`potvrdi`}</button>
                    </Col>
                  </Row>       
         </Form>
        )}

        </div>
      )
    }
  }
