import React, { Component } from 'react';
import PosljednjeOcitanoStanje from './PosljednjeOcitanoStanje'
import PrijavaNovogStanja from './PrijavaNovogStanja'
import PrijavljenoNovoStanje from './PrijavljenoNovoStanje'
import PrijavljenoNeprihvacenoStanje from './PrijavljenoNeprihvacenoStanje'
import Utrosak from './Utrosak'
import { Button, Row, Col } from "reactstrap";
import { t } from 'ttag';
import { PRIJAVA_NOVOG_STANJA_URL, DELETE_PRIJAVLJENO_STANJE_URL } from '../const';
import { authenticationService } from '../authentication/AuthenticationService';
import Modal from 'react-modal';
import './PrijavaStanjaView.css'
import CustomButton from '../Component/CustomButton';

class PrijavaStanjaForma extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visaTarifaUnos: 0,
            nizaTarifaUnos: 0,
            utrosakMt: 0,
            utrosakVt: 0,
            hasError: false,
            error: "",
            modalErrorVisible: false,
            modalSuccessVisible: false,
            obrisanoNovoStanje: false   
        }
    }

    visaTarifaOnChange = (event) => {
        let utrosak = 0;

        if (event.target.value === '') {
            utrosak = this.props.ocitanoStanje.avtReading - this.props.ocitanoStanje.avtReading;
        } else {
            utrosak = event.target.value - this.props.ocitanoStanje.avtReading;
        }

        this.setState({
            visaTarifaUnos: event.target.value,
            utrosakVt: utrosak
        })
    }

    nizaTarifaOnChange = (event) => {
        let utrosak = 0;
        if (event.target.value === '') {
            utrosak = this.props.ocitanoStanje.amtReading - this.props.ocitanoStanje.amtReading;
        } else {
            utrosak = event.target.value - this.props.ocitanoStanje.amtReading;
        }

        this.setState({
            nizaTarifaUnos: event.target.value,
            utrosakMt: utrosak
        })
    }

    prijavaStanja = (mjernoMjestoId) => {
        let customerNumber = sessionStorage.getItem("naplatniBroj")
        if (mjernoMjestoId === 0) {
            return;
        }
        fetch(PRIJAVA_NOVOG_STANJA_URL(customerNumber, mjernoMjestoId), {
            method: "POST",
            headers: authenticationService.authenticationHeader(),
            body: JSON.stringify({
                avt: this.state.visaTarifaUnos,
                amt: this.state.nizaTarifaUnos
            })
        })
            .then(results => {
                if (results.status !== 200) {
                    this.setState({
                        hasError: true,
                        modalErrorVisible: true,
                        modalSuccessVisible: false
                    })
                    return results.json();
                }
                else {
                    this.setState({
                        modalErrorVisible: false,
                        modalSuccessVisible: true
                    })
                }
            })
            .then((jsonMessage) => {
                this.setState({
                    error: jsonMessage.message
                })
            })
    }

    deletePrijavljenoStanje = () => {
        let customerNumber = sessionStorage.getItem("naplatniBroj")
        if (this.props.mjernoMjestoId === 0) {
        return;
        }
        fetch(DELETE_PRIJAVLJENO_STANJE_URL(customerNumber, this.props.mjernoMjestoId), {
        method: "DELETE",
        headers: authenticationService.authenticationHeader()
        })
        .then(results => {
            if(results.status === 200){
            this.setState({
                obrisanoNovoStanje: true
            })
            }else{
            this.setState({
                obrisanoNovoStanje: false
            })
            }
        })
    }

    buttonPrijaviClicked = () => {
        this.prijavaStanja(this.props.mjernoMjestoId);
    }

    closeModal = () => {
        this.setState({
            modalErrorVisible: false,
            modalSuccessVisible: false,
            obrisanoNovoStanje: false
        })
    }

    refreshPage = () => {
        window.location.reload();
    }

    render() {

        const modalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginBottom: '-50%',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                //height: '75%',
                width: '350px',
                height: '200px'
            }
        };

        return (

            <div className="card card-box">
                <Row className="card-body mt-3">
                    <Col className="" xs='12' xl='4'>
                        <PosljednjeOcitanoStanje ocitanoStanje={this.props.ocitanoStanje}></PosljednjeOcitanoStanje>
                    </Col>
                    <Col className={this.props.postojiPrijavljenoStanje === false ? "mt-3 mt-xl-0" : "notDisplay"} xs='12' xl='4'>
                        <PrijavaNovogStanja prijavljenoStanje={this.props.prijavljenoStanje} visaTarifaOnChange={this.visaTarifaOnChange} nizaTarifaOnChange={this.nizaTarifaOnChange}></PrijavaNovogStanja>
                    </Col>
                    <Col className={(this.props.postojiPrijavljenoStanje === true && this.props.prijavljenoStanje.alreadyAccepted === 1) ? "mt-3 mt-xl-0" : "notDisplay"} xs='12' xl='4'>
                        <PrijavljenoNovoStanje prijavljenoStanje={this.props.prijavljenoStanje}></PrijavljenoNovoStanje>
                    </Col>
                    <Col className={(this.props.postojiPrijavljenoStanje === true && this.props.prijavljenoStanje.alreadyAccepted !== 1) ? "mt-3 mt-xl-0" : "notDisplay"} xs='12' xl='4'>
                        <PrijavljenoNeprihvacenoStanje postojiPrijavljenoStanje={this.props.postojiPrijavljenoStanje} obrisanoNovoStanje={this.props.obrisanoNovoStanje} deletePrijavljenoStanje={this.deletePrijavljenoStanje} prijavljenoStanje={this.props.prijavljenoStanje} visaTarifaOnChange={this.visaTarifaOnChange} nizaTarifaOnChange={this.nizaTarifaOnChange}></PrijavljenoNeprihvacenoStanje>
                    </Col>
                    <Col className="mt-3 mt-xl-0" xs='12' xl='4'>
                        <Utrosak postojiPrijavljenoStanje={this.props.postojiPrijavljenoStanje} prihvacenoNovoStanje={this.props.prijavljenoStanje.alreadyAccepted} prijavljenoStanje={this.props.prijavljenoStanje} ocitanoStanje={this.props.ocitanoStanje} utrosakVt={this.state.utrosakVt} utrosakMt={this.state.utrosakMt}></Utrosak>
                    </Col>
                </Row>
                <div className={this.props.postojiPrijavljenoStanje === true ? "notDisplay" : "card-footer footer-box d-flex d-flex flex-row-reverse justify-content-center mt-3"}>
                    <CustomButton onClick={this.buttonPrijaviClicked} icon="lnc-check" text={t`prijaviStanje`}></CustomButton>
                </div>
                <div className={this.props.postojiPrijavljenoStanje === true ? "card-footer footer-box d-flex flex-row-reverse d-flex justify-content-center mt-3" : "notDisplay"}>   
                    <CustomButton onClick={this.buttonPrijaviClicked} disabled={true} icon="lnc-check" text={t`prijaviStanje`}></CustomButton>

                </div>

                <div>
                    <Modal style={modalStyle} isOpen={this.state.modalSuccessVisible}>
                        <br></br>
                        <h8>{t`uspjesnoPrijavljenoStanje`}</h8><br></br><br></br>
                        <Button className="btnPrijaviStanje mt-4 mr-0 float-right btn-block" onClick={()=>{this.closeModal(); this.refreshPage();}}>{t`uRedu`}</Button>
                    </Modal>
                </div>

                <div>
                    <Modal style={modalStyle} isOpen={this.state.modalErrorVisible}>
                        <br></br>
                        <h8>{this.state.error}</h8><br></br><br></br>
                        <Button className="btnPrijaviStanje mt-4 mr-0 float-right btn-block" onClick={this.closeModal}>{t`uRedu`}</Button>
                    </Modal>
                </div>

                <div>
                    <Modal style={modalStyle} isOpen={this.state.obrisanoNovoStanje}>
                        <br></br>
                        <h8>Uspješno ste izbrisali prijavljeno stanje.</h8><br></br><br></br>
                        <Button className="btnPrijaviStanje mt-4 mr-0 float-right btn-block" onClick={()=>{this.closeModal(); this.refreshPage();}}>{t`uRedu`}</Button>
                    </Modal>
                </div>

            </div>
        );
    }
}

export default PrijavaStanjaForma;