import React from 'react'
import KalkulatorPotrosnjeForma from './KalkulatorPotrosnjeForma'
import { Input, Row, Spinner } from 'reactstrap';
import { MJERNA_MJESTA_ALL_URL, OCITANJA_LAST_URL } from '../const';
import { authenticationService } from '../authentication/AuthenticationService';
import "./KalkulatorPotrosnjeView.css";
import { t } from "ttag";
import { formatDate } from '../utils/formater';

export default class KalkulatorPotrosnjeView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userId: 0,
      mjernoMjestoId: 0,
      mjernaMjesta: [],
      ocitanoStanje: { avtReading: 0, amtReading: 0 },
      tipKalkulatoraValue: 1,
      isLoaded: false,
      numRows: 0
    }
  }

  getMjernaMjesta = () => {
    let customerNumber = sessionStorage.getItem("naplatniBroj")
        fetch(MJERNA_MJESTA_ALL_URL(customerNumber), {
      method: "GET",
      headers: authenticationService.authenticationHeader()
    })
      .then(results => results.json())
      .then(data => {
        this.getPoslednjaOcitanaStanja(data[0].id);
        this.setState({
          mjernaMjesta: data,
          mjernoMjestoId: data[0].id,
          // isLoaded: true,
          numRows: data.length
        })
      });
  }

  getPoslednjaOcitanaStanja = (mjernoMjestoId) => {
    let customerNumber = sessionStorage.getItem("naplatniBroj")
    if (this.props.mjernoMjestoId === 0) {
      return;
    }
    fetch(OCITANJA_LAST_URL(customerNumber, mjernoMjestoId), {
      method: "GET",
      headers: authenticationService.authenticationHeader()
    }).then(results => results.json())
      .then(data => {
        this.setState({
          ocitanoStanje: data,
          isLoaded: true
        })
      });
  }

  selectedMjernoMjesto = (event) => {
    this.setState({
      mjernoMjestoId: event.target.value
    })
    let mjernoMjestoId = event.target.value;
    this.getPoslednjaOcitanaStanja(mjernoMjestoId);
  }

  componentDidMount() {
    let userId = authenticationService.getUser().id;
    this.setState({
      userId: userId
    });
    this.getMjernaMjesta();
  }

  render() {
    if (this.state.isLoaded) {
    return (
      <div className="w-100 p-1 overflow-hidden">
        <h4 className="titleKalkulatorPotrosnje">{t`kalkulatorPotrosnje`}</h4><br></br>
        <div className="form-group w-100">
          
            <div className="justify-content-start">
              <Row>
                  <label className="mt-1 col-auto">{t`mjernoMjesto`}:</label>
                  <div className="col-12 col-md-12 col-xl-5">
                  <Input  disabled={this.state.numRows > 1 ? false : true} className="text form-control-sm" onChange={this.selectedMjernoMjesto} type="select" id="mjerno-mjesto">
                    {
                      this.state.mjernaMjesta.map(mm => {
                        return <option key={mm.id} value={mm.id}>{mm.address}</option>
                      })
                    }
                  </Input>
                  </div>
              </Row>

              <Row className="pt-3 pl-3">
                <div>
                    <label className="w-50 d-inline mr-3">{t`brojBrojila`}:</label>
                    <label className="text">{this.state.ocitanoStanje.activeMeterNumber}</label>
                </div>
              </Row>

              <Row className="p-3">
                <div>
                  <label className="">{t`posljednjeOcitanoStanje`}:</label>
                  <label className="text">{formatDate(this.state.ocitanoStanje.readingDate)}/</label>  
                </div>
                <div>
                  <label className="">{t`visaTarifa`} (kWh):</label>
                  <label className="text">{this.state.ocitanoStanje.avtReading}/</label>
                </div>
                <div>
                  <label className="">{t`nizaTarifa`} (kWh):</label>
                  <label className="text">{this.state.ocitanoStanje.amtReading}</label>
                </div>
              </Row> 
            </div>
          <br></br>

        </div>
        <KalkulatorPotrosnjeForma ocitanoStanje={this.state.ocitanoStanje} userId={this.state.userId} mjernoMjestoId={this.state.mjernoMjestoId}></KalkulatorPotrosnjeForma>
      </div>
    );
    }
    return (
      <Spinner className="payment-spiner" color="primary" />
    );
  }

}