import React, { Component } from 'react';
import { Row, Col, Input } from "reactstrap";
import { t } from 'ttag';


class Utrosak extends Component {
    render() {
        return (
            <div className="card whole-box">
                <h5 className="card-header header-box text text-center">{t`utrosak`}</h5>
                <Row className="card-body body-box">
                    <Col xs='12' className='text-center m-auto'>
                        <Row>
                            <Col xs='4' className="pt-xl-2"><label className="float-left ml-3">{t`avt`} (kWh):</label></Col>
                            <Col xs='8'><Input className="float-left ml-3 ml-sm-0 w-100 font-weight-bold d-inline" type="text" value={this.props.postojiPrijavljenoStanje === true ? (this.props.prijavljenoStanje.avt-this.props.ocitanoStanje.avtReading) : this.props.utrosakVt}/*{this.props.utrosakVt}*/ disabled></Input></Col>
                        </Row>
                    </Col>
                    <Col xs='12' className='text-center mt-4 ml-auto mr-auto'>
                        <Row>
                            <Col xs='4' className="pt-xl-2"><label className="float-left ml-3">{t`amt`} (kWh):</label></Col>
                            <Col xs='8'><Input className="float-left ml-3 ml-sm-0 w-100 font-weight-bold d-inline" type="text" value={this.props.postojiPrijavljenoStanje === true ? (this.props.prijavljenoStanje.amt-this.props.ocitanoStanje.amtReading) : this.props.utrosakMt}/*{this.props.utrosakMt}*/ disabled></Input></Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Utrosak;