import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { t } from 'ttag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { NOTIFICATION, NOTIFICATION_COUNT } from '../const';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { formatDateTime } from '../utils/formater';
import { authenticationService } from '../authentication/AuthenticationService';
import { Link } from 'react-router-dom';
import {AppContext} from '../Store';
import './Notifications.css';


let prev = 0
let last = 0
export class AllNotifications extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lists: [],
      number: 0,
      flagRead: false,
      currentPage: 1,
      todosPerPage: 4
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleLastClick = this.handleLastClick.bind(this)
    this.handleFirstClick = this.handleFirstClick.bind(this)
  }

  handleClick (event) {
    event.preventDefault()
    this.setState({
      currentPage: Number(event.target.id),
      selectedValue: event.target.value

    })
  }

  handleLastClick (event) {
    event.preventDefault()
    this.setState({
      currentPage: last
    })
  }
  handleFirstClick (event) {
    event.preventDefault()
    this.setState({
      currentPage: 1
    })
  }
  componentDidMount () {
    fetch(NOTIFICATION, {
      method: 'GET',
      headers: authenticationService.authenticationHeader()
    })
      .then(response => {
        return response.json()
      })
      .then(e => {
        this.setState({
          lists: e
        })
      })
      .catch(error => {
      })
    this.countNotifications();
  }

  countNotifications = () => {
    fetch(NOTIFICATION_COUNT, {
      method: 'GET',
      headers: authenticationService.authenticationHeader()
    })
      .then(response => {
        return response.json()
      })
      .then(e => {
        this.setState({
          number: e
        })
      })
      .catch(error => {
      })
  }

  render () {


    let lists = this.state.lists.map(item => (
      <NotificationItem  key={item.id} item={item} />
    ))
    let { currentPage, todosPerPage } = this.state

    // Logic for displaying current todos
    let indexOfLastTodo = currentPage * todosPerPage
    let indexOfFirstTodo = indexOfLastTodo - todosPerPage
    let currentTodos = lists.slice(indexOfFirstTodo, indexOfLastTodo)
    prev = currentPage > 0 ? currentPage - 1 : 0
    last = Math.ceil(lists.length / todosPerPage)
    //let next = last === currentPage ? currentPage : currentPage + 1

    // Logic for displaying page numbers
    let pageNumbers = []
    for (let i = 1; i <= last; i++) {
      pageNumbers.push(i)
    }
    

/**border rounded */

    return (
      <Container  className='container-fluid a bg-white'>
        <Row className=''>
          <Col lg={10} sm={7} className='align-self-center'>
            <h5 className='title'>{t`obavjestenja`}</h5>
          </Col>
        </Row>    
          <div className="container-fluid"  > 
            {currentTodos.map((todo, index) => {
              return <div key={index}>{todo}</div>
            })}
          </div>
        <Row className='paging-row'>
          <ul id='page-numbers' className='page-numbers'>
            <nav>
              <Pagination >
                <PaginationItem>
                  {prev === 0 ? (
                    <PaginationLink disabled className='pag-color'>{t`prva`}</PaginationLink>
                  ) : (
                    <PaginationLink
                      onClick={this.handleFirstClick}
                      id={prev}
                      href={prev}
                      className='pag-color'
                    >
                      {t`prva`}
                    </PaginationLink>
                  )}
                </PaginationItem>
                <PaginationItem>
                  {prev === 0 ? (
                    <PaginationLink disabled className='pag-color'>{t`pret`}</PaginationLink>
                  ) : (
                    <PaginationLink
                      onClick={this.handleClick}
                      id={prev}
                      href={prev}
                      className='pag-color'
                    >
                      {t`pret`}
                    </PaginationLink>
                  )}
                </PaginationItem>
                {pageNumbers.map((number, i) => (
                  <Pagination key={i}>
                    <PaginationItem
                      active={pageNumbers[currentPage - 1] === number}
                    >
                      <PaginationLink
                        onClick={this.handleClick}
                        href={number}
                        key={number}
                        id={number}
                        className='pag-color'
                      >
                        {number}
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                ))}

                <PaginationItem>
                  {currentPage === last ? (
                    <PaginationLink disabled className='pag-color'>{t`sl`}</PaginationLink>
                  ) : (
                    <PaginationLink 
                      onClick={this.handleClick}
                      id={pageNumbers[currentPage]}
                      href={pageNumbers[currentPage]}
                      className='pag-color'
                    >
                      {t`sl`}
                    </PaginationLink>
                  )}
                </PaginationItem>

                <PaginationItem>
                  {currentPage === last ? (
                    <PaginationLink disabled className='pag-color'>{t`poslj`}</PaginationLink>
                  ) : (
                    <PaginationLink
                      onClick={this.handleLastClick}
                      id={pageNumbers[currentPage]}
                      href={pageNumbers[currentPage]}
                      className='pag-color'
                    >
                      {t`poslj`}
                    </PaginationLink>
                  )}
                </PaginationItem>
              </Pagination>
            </nav>
          </ul>
        </Row>
      </Container>  
    )
  }
}

const NotificationItem = props => (
  <AppContext.Consumer>
  {({ messageNumber, setMessageNumber }) => (
    <div className='notification-item'>
      <label className='item-date'>
        {formatDateTime(props.item.notificationSysdate)}
      </label>
    <Row className= 'notification-header'>
      <Col lg={10} sm={4} className='item-header'>
      <Link  to={`/notification/${props.item.notificationId}`}>
      {!props.item.flagRead ? (
          <strong onClick = {() => setMessageNumber(messageNumber-1)} className='item-header'>{props.item.header}</strong>
      ):( <strong className='item-header'>{props.item.header}</strong>)}
        </Link>
      </Col>
    </Row>

    <Row>
      <div className='item-content'>{props.item.content}</div>
      {props.item.flagRead ? (
            <div className='item-flag'>
              {props.item.flagRead}
            </div>
             ) : (
                <div className='item-flag'>
                    <FontAwesomeIcon icon={faEyeSlash} className='icon2' />
                    {props.item.flagRead}
                </div>
          )}
      </Row>  
</div>
 )}
 </AppContext.Consumer>
    )
export default AllNotifications