import React from 'react';
import SideMenuBig from './SideMenuSmall';
import SideMenuSmall from './SideMenuBig';
import MainContent from './MainContent';
import { saveLocale } from '../localization/i18n';
import { AppContext } from '../Store';
import { PICTURE_URL, NOTIFICATION_COUNT } from '../const';
import { authenticationService } from '../authentication/AuthenticationService';
import './MainView.css';


export default class MainView extends React.Component {
  setPicture = picture => {
    this.setState({ picture });
  };
  setMessageNumber = messageNumber => {
    this.setState({ messageNumber });
  };
  state = {
    picture: null,
    setPicture: this.setPicture,
    sideMenuOpen: false,
    messageNumber: null,
    setMessageNumber: this.setMessageNumber
  };

  componentDidMount() {
    fetch(PICTURE_URL, {
      method: 'GET',
      headers: authenticationService.authenticationHeader(),
    }).then(response => {
      return response.blob();
    }).then(responseData => {
      this.setState({
        picture: URL.createObjectURL(responseData)
      })

    })
    this.countNotifications();
    let user = authenticationService.getUser();
    if (user.language.languageId === 2) {
      saveLocale('lat')
    } else {
      saveLocale('cir')
    }

  }

  countNotifications = () => {
    fetch(NOTIFICATION_COUNT, {
      method: 'GET',
      headers: authenticationService.authenticationHeader()
    })
      .then(response => {
        return response.json()
      })
      .then(e => {
        this.setState({
          messageNumber: e
        })
      })
      .catch(error => {
      })
  }



  sideMenuToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideMenuOpen: !prevState.sideMenuOpen }
    })
  }

  sideMenuItemClick = () => {
    this.setState({
      sideMenuOpen: false
    })
  }
  
  render() {
    return (
      <AppContext.Provider value={this.state}>
        <div>
          <SideMenuBig sideMenuToggleClickHandler={this.sideMenuToggleClickHandler} clickHandler={this.sideMenuItemClick} show={this.state.sideMenuOpen} />
          <SideMenuSmall sideMenuToggleClickHandler={this.sideMenuToggleClickHandler} clickHandler={this.sideMenuItemClick} show={this.state.sideMenuOpen} />
          <MainContent />
        </div>
      </AppContext.Provider>
    );
  }
}
