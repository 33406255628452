import React, { Component } from 'react';
import { Row, Col, Input } from "reactstrap";
import { t } from 'ttag';
import './PrijavaStanjaView.css'

class PrijavaNovogStanja extends Component {

    render() {
        return (
            <div className="card whole-box">
                <h5 className="card-header header-box text text-center">{t`prijavaNovogStanja`}</h5>                
                <Row className="card-body body-box">
                    <label className={(this.props.postojiPrijavljenoStanje === true && this.props.prijavljenoStanje.alreadyAccepted === 0) ? "text-center m-auto" : "notDisplay"}>{t`prijavljenoNeprihvacenoStanje`}!</label>
                    <label className={(this.props.postojiPrijavljenoStanje === true && this.props.prijavljenoStanje.alreadyAccepted === -1) ? "text-center m-auto" : "notDisplay"}>{t`prijavljenoOdbacenoStanje`}!</label>
                    <Col xs='12' className="text-center mt-0">
                        <Row>
                            <Col xs='4' className="pt-xl-2"><label className="float-left ml-3">{t`avt`} (kWh):</label></Col>
                            <Col xs='8'><Input type="number" readOnly value={this.props.prijavljenoStanje.avt} className="float-left w-100 ml-3 ml-sm-0 font-weight-bold d-inline"></Input></Col>
                        </Row>
                    </Col>
                    <Col xs='12' className='text-center mt-4 ml-auto mr-auto'>
                        <Row>
                            <Col xs='4' className="pt-xl-2"><label className="float-left ml-3">{t`amt`} (kWh):</label></Col>
                            <Col xs='8'><Input type="number" readOnly value={this.props.prijavljenoStanje.amt} className="float-left w-100 ml-3 ml-sm-0 font-weight-bold d-inline"></Input></Col>
                        </Row>
                    </Col>
                    {/* <Col xs='12'>
                        <Button className="mr-3 mt-3 float-right" color="success" onClick={this.props.deletePrijavljenoStanje}>{t`obrisi`}</Button>
                    </Col> */}
                </Row>
            </div>
        );
    }
}

export default PrijavaNovogStanja;