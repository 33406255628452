import React, { Component } from "react";
import { Spinner, Container, Row, Col } from "reactstrap";
import { t } from "ttag";
import { SERVICE_PARTNER_URL, LAST_PAYMENT_URL, LAST_INVOICE_URL } from "../const";
import { authenticationService } from "../authentication/AuthenticationService";
import './Homeview.css';

import { formatDate, formatNumber } from '../utils/formater';

export default class CustomerInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      partner: [],
      paymentData: [],
      invoiceData: [],
      boxId: ''
    };
  }
  componentDidMount() {
    let headers = authenticationService.authenticationHeader();
    let customerNumber = sessionStorage.getItem("naplatniBroj")
    fetch(SERVICE_PARTNER_URL (customerNumber), {
      method: 'GET',
      headers: headers
    }).then(response => {
      return response.json();
    }).then(data => {
      this.setState({
        partner: data
      })
      this.state.partner.balance > 0 ?
        this.setState({
          boxId: "home-box-negativ"
        })
        :
        this.setState({
          boxId: "home-box-positiv"
        })
    }).catch(error => {
    })

    fetch(LAST_PAYMENT_URL(customerNumber), {
      method: 'GET',
      headers: headers
    }).then(response => {
      return response.json();
    }).then(data => {
      this.setState({
        paymentData: data,
      })
    }).catch(error => {
    });

    fetch(LAST_INVOICE_URL(customerNumber), {
      method: 'GET',
      headers: headers
    }).then(response => {
      return response.json();
    }).then(data => {
      this.setState({
        invoiceData: data,
        isLoaded: true
      })
    }).catch(error => {
    });

  }

  render() {
    if (this.state.isLoaded) {
      return (
        <>
          <Container fluid className="home-content" >
            <Row >
              <InfoBox id={this.state.boxId} label={t`trenutnoStanje`} value={formatNumber(this.state.partner.balance)} sufix=" KM" icon="lnc lnc-file-text" />
              <InfoBox id="home-box2" label={t`poslRacun`} value={formatNumber(this.state.invoiceData.amount)} sufix=" KM" icon="lnc lnc-bill" />
              <InfoBox id="home-box3" label={t`poslednjaUpl`} value={formatNumber(this.state.paymentData.amount)} sufix=" KM" icon="lnc lnc-wallet" />
              <InfoBox id="home-box4" label={t`naplatniBroj`} value={this.state.partner.partnerIdentifier} icon="lnc lnc-scan-number" />
            </Row>
            <Row className="mt-3">
              <Col sm="6" md="6" lg="6" xl="3">
                <i className="home-info-card-icon lnc lnc-wallet"></i>
                <h4 className="home-h4 home-font home-info-card-label text-uppercase">{t`kontakt`}:</h4>
                <h6 className="home-info-card-h6">{t`email`}:</h6>
                <h5 className="home-info-card-h5 home-word-wrap">{this.state.partner.email ? this.state.partner.email : "-"}</h5>
                <h6 className="home-info-card-h6">{t`fiksni`}:</h6>
                <h5 className="home-info-card-h5">{this.state.partner.telephone ? this.state.partner.telephone : "-"}</h5>
                <h6 className="home-info-card-h6">{t`mobilni`}:</h6>
                <h5 className="home-info-card-h5">{this.state.partner.mobile ? this.state.partner.mobile : "-"}</h5>
                <h6 className="home-info-card-h6">{t`dostavaFakt`}:</h6>
                <h5 className="home-info-card-h5 ">{this.state.partner.invoiceAddress}</h5>
              </Col>
              <Col sm="6" md="6" lg="6" xl="3">
                <i className="home-info-card-icon lnc lnc-bill fa-2x"></i>
                <h4 className="home-h4 home-font home-info-card-label text-uppercase">{t`poslRacunDetalji`}:</h4>
                <h6 className="home-info-card-h6">{t`period`}:</h6>
                <h5 className="home-info-card-h5">{this.state.invoiceData.period}</h5>
                <h6 className="home-info-card-h6">{t`iznos`}:</h6>
                <h5 className="home-info-card-h5">{formatNumber(this.state.invoiceData.amount) + " KM"}</h5>
              </Col>
              <Col sm="6" md="6" lg="6" xl="3">
                <i className="home-info-card-icon lnc lnc-wallet"></i>
                <h4 className="home-h4  home-font home-info-card-label text-uppercase">{t`poslednjaUplDetalji`}:</h4>
                <h6 className="home-info-card-h6">{t`datum`}:</h6>
                <h5 className="home-info-card-h5">{formatDate(this.state.paymentData.paymentDate)}</h5>
                <h6 className="home-info-card-h6">{t`iznos`}:</h6>
                <h5 className="home-info-card-h5">{formatNumber(this.state.paymentData.amount) + " KM"}</h5>
                <h6 className="home-info-card-h6">{t`banka`}:</h6>
                <h5 className="home-info-card-h5">{this.state.paymentData.bank}</h5>
              </Col>
              <Col sm="6" md="6" lg="6" xl="3">
                <i className="home-info-card-icon lnc lnc-location fa-2x"></i>
                <h4 className="home-h4  home-font home-info-card-label text-uppercase">{t`krajnjiKupac`}:</h4>
                <h6 className="home-info-card-h6">{t`naziv`}:</h6>
                <h5 className="home-info-card-h5 text-uppercase">{this.state.partner.name}</h5>
                <h6 className="home-info-card-h6">{t`adresa`}:</h6>
                <h5 className="home-info-card-h5 text-uppercase">{this.state.partner.address}</h5>
                <h6 className="home-info-card-h6">{t`kategorija`}:</h6>
                <h5 className="home-info-card-h5">{this.state.partner.scheme}</h5>
                <h6 className="home-info-card-h6">{t`mmAktivno`}:</h6>
                <h5 className="home-info-card-h5">{this.state.partner.turnedOff ? 'NE' : 'DA'}</h5>
              </Col>
            </Row>
          </Container>
        </>
      )
    }
    return (
      <Spinner className="payment-spiner" color="primary" />
    )
  }
}

const InfoBox = props => {
  let iconClassName = "home-info-box-icon ".concat(props.icon)
  return (<div className="no-pad-mar col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
    <div id={props.id} className="home-info-box">
      <div className="row">
        <div className="no-pad-mar home-info-icon-content col-lg-2 col-md-2 col-sm-3 col-2"> <i className={iconClassName}></i></div>
        <div className="no-pad-mar col-lg-9 col-md-10 col-sm-9 col-9 master">
          <h4 className="home-h4 home-font home-info-box-label text-uppercase"> {props.label}</h4>
          <h4 className="home-h4 home-font home-info-box-value">{props.value} {props.sufix}</h4>
        </div>
      </div>
    </div>
  </div >)
}


