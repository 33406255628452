import React from 'react'
import { authenticationService } from "../authentication/AuthenticationService";
import { UPLATE_ALL_URL, UPLATNICE_PDF_URL } from '../const';
import { Table } from 'reactstrap';
import { t } from "ttag";
import { Spinner } from 'reactstrap';
import { formatDate, formatNumber } from '../utils/formater';
import CustomButton from '../Component/CustomButton';
import './UplateView.css'

export default class UplateView extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoaded: false,
      payments: []
    }
  }

  preuzmiUplatnice = () => {
    let customerNumber = sessionStorage.getItem("naplatniBroj")
    fetch(UPLATNICE_PDF_URL(customerNumber), {
      method: "GET",
      headers: authenticationService.authenticationHeader()
    })
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'uplatnice.pdf';
          a.click();
        })
      })
  }

  componentDidMount() {
    let customerNumber = sessionStorage.getItem("naplatniBroj")
    fetch(UPLATE_ALL_URL(customerNumber), {
      method: 'GET',
      headers: authenticationService.authenticationHeader()
    }).then(response => {
      return response.json();
    }).then(data => {
      this.setState({
        payments: data,
        isLoaded: true
      })
    })
  }

  render() {
    let payments = this.state.payments.map(item => <TableRow key={item.id} item={item} />)
    let paymentsBox = this.state.payments.map(item => <PaymentBox key={item.id} item={item} />)
    if (this.state.isLoaded) {
      return (
        <>
          <div className="container-fluid">
            <div>
              <h4 className="payment-page-title">{t`uplate`}</h4><br />
            </div>
            <div>
              <CustomButton style={{ float: 'left' }} text={t`preuzmiUplatnice`} icon="lnc-bell" onClick={this.preuzmiUplatnice} />
            </div>
            <br />
            <div className="row payment-table-view">
              <Table className="payment-table" size="sm" borderless striped>
                <TableHeader />
                <tbody>
                  {payments}
                </tbody>
              </Table>
            </div>
            <div className="payment-box-view">
              {paymentsBox}
            </div>
          </div>
        </>
      );
    }
    return (
      <Spinner className="payment-spiner" color="primary" />
    )
  }
}

const PaymentBox = props => (
  <div className="payment-box">
    <Table borderless size="sm" striped>
      <tbody>
        <tr>
          <td className="text-font-size payment-td-box-left">{t`datumUplate`}</td>
          <td className="text-font-size payment-td-box-right"> {formatDate(props.item.paymentDate)}</td>
        </tr>
        <tr>
          <td className="text-font-size payment-td-box-left">{t`banka`}</td>
          <td className="text-font-size payment-td-box-right">{props.item.bank}</td>
        </tr>
        <tr>
          <td className="text-font-size payment-td-box-left">{t`ziroRacun`}</td>
          <td className="text-font-size payment-td-box-right">{props.item.bankAccount}</td>
        </tr>
        <tr>
          <td className="text-font-size payment-td-box-left">{t`iznosKM`}</td>
          <td className="text-font-size payment-box-value payment-td-box-right">{formatNumber(props.item.amount)}</td>
        </tr>
      </tbody>
    </Table>
  </div>
)

const TableHeader = () => (
  <thead className="payment-thead">
    <tr>
      <th className="payment-th">{t`datumUplate`}:</th>
      <th className="payment-th">{t`banka`}:</th>
      <th className="payment-th">{t`ziroRacun`}:</th>
      <th className="payment-th">{t`iznosKM`}:</th>
    </tr>
  </thead>
);

const TableRow = props => (
  <tr>
    <td className="payment-td payment-text"> {formatDate(props.item.paymentDate)}</td>
    <td className="payment-td payment-text"> {props.item.bank}</td>
    <td className="payment-td payment-text"> {props.item.bankAccount}</td>
    <td className="payment-td payments-value pr-2"> {formatNumber(props.item.amount)}</td>
  </tr>
);