import { LOGIN_URL, FORGOT_PASSWORD, SIGN_UP, CUSTOMER_NUMBERS  } from '../const'
export const authenticationService = {
  login,
  isLoggedIn,
  logout,
  getUser,
  signUp,
  authenticationHeader,
  setAuthData,
  resetPassword,
  isHost,
  getCustomerNumbers
}

const userKey = 'user'

class UserStatus {
  success = false
  error = ''
  constructor(success, error) {
    this.success = success
    this.error = error
  }
}

function login(username, password) {
  return fetch(LOGIN_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username,
      password: password
    })
  })
    .then(response => {
      return response
        .json()
        .then(data => ({ status: response.status, body: data }))
    })
    .then(responseData => {
      if (responseData.status === 200) {
        let user = responseData.body
        user.authdata = window.btoa(username + ':' + password)
        document.cookie = 'JSESSIONID=' + user.jsessionId
        sessionStorage.setItem(userKey, JSON.stringify(user))
        sessionStorage.setItem("naplatniBroj", user.customerNumber)

        return new UserStatus(true, '')
      } else {
        let error = responseData.body
        sessionStorage.removeItem(userKey)
        sessionStorage.removeItem("naplatniBroj")
        return new UserStatus(false, error.message)
      }
    })
    .catch(error => {
      return new UserStatus(false, error.message)
    })
}

function getCustomerNumbers () {
   fetch(CUSTOMER_NUMBERS, {
    method: 'GET',
    headers: authenticationService.authenticationHeader(),
})  .then(response => response.json())
.then(data => {return data});
}

function resetPassword(email) {
  return fetch(FORGOT_PASSWORD, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: email
    })
  }).then(response => {
    if(response.status === 200){
      return new UserStatus(true,'');
    } else {
    return response
      .json()
      .then(data => {
        return new UserStatus(false, data.message);
      })      
    }
  })
  .catch(error => {
    return new UserStatus(false, error.message)
  })
}

function signUp(firstName, lastName, address, phone, email, customerNumber, controlNumber) {
  return fetch(SIGN_UP, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      address: address,
      phone: phone,
      email: email,
      potNpb: customerNumber,
      controlNumber: controlNumber
    })
  }).then(response => {
    if(response.status === 200){
      return new UserStatus(true,'');
    } else {
    return response
      .json()
      .then(data => {
        return new UserStatus(false, data.message);
      })      
    }
  })
  .catch(error => {
    return new UserStatus(false, error.message)
  })
}

function logout() {
  sessionStorage.removeItem(userKey)
  sessionStorage.removeItem("naplatniBroj")
}

function isLoggedIn() {
  return sessionStorage.getItem(userKey) != null
}

function getUser() {
  return JSON.parse(sessionStorage.getItem(userKey))
}

function setAuthData(user, newPassword) {
  user.authdata = window.btoa(user.username + ':' + newPassword)
  sessionStorage.setItem(userKey, JSON.stringify(user))
}
function isHost() {
  return getUser().scheme.schemeId === 1 ? true : false;
}

function authenticationHeader() {
  let user = JSON.parse(sessionStorage.getItem(userKey))
  if (user && user.authdata) {
    return {
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + user.authdata
    }
  } else {
    return {}
  }
}
