import React, { Component } from 'react';
import { Row, Col, Input } from "reactstrap";
import { t } from 'ttag';
import './PrijavaStanjaView.css'

class PrijavaNovogStanja extends Component {

    render() {
        return (
            <div className="card whole-box">
                <h5 className="card-header header-box text text-center">{t`prijavaNovogStanja`}</h5>
                <Row className="card-body body-box">
                    {/* polja koja se prikazuju ukoliko nije prijavljeno novo stanje */}
                    <Col xs='12' className='text-center m-auto'>
                        <Row>
                            <Col xs='4' className="pt-xl-2"><label className="float-left ml-3">{t`avt`} (kWh):</label></Col>
                            <Col xs='8'><Input onChange={this.props.visaTarifaOnChange} className="float-left font-weight-bold ml-3 ml-sm-0 w-100 d-inline" type="number" placeholder={t`visaTarifa`}></Input></Col>
                        </Row>
                    </Col>
                    <Col xs='12' className='text-center mt-4 ml-auto mr-auto'>
                        <Row>
                            <Col xs='4' className="pt-xl-2"><label className="float-left ml-3">{t`amt`} (kWh):</label></Col>
                            <Col xs='8'><Input onChange={this.props.nizaTarifaOnChange} className="float-left font-weight-bold ml-3 ml-sm-0 w-100 d-inline" type="number" placeholder={t`nizaTarifa`}></Input></Col>
                        </Row>
                    </Col>
                </Row>
            </div> 
        );
    }
}

export default PrijavaNovogStanja;