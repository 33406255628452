import React from 'react'
import PrijavaStanjaForma from './PrijavaStanjaForma'
import './PrijavaStanjaView.css'
import { authenticationService } from '../authentication/AuthenticationService'
import { MJERNA_MJESTA_ALL_URL, OCITANJA_LAST_URL, GET_PRIJAVLJENO_STANJE_URL, IS_PRIJAVLJENO_NOVO_STANJE_URL } from '../const';
import { Input, Col, Spinner, Row } from "reactstrap";
import { t } from "ttag";

export default class PrijavaStanjaView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userId: 0,
      mjernoMjestoId: 0,
      mjernaMjesta: [],
      ocitanoStanje: { avtReading: 0, amtReading: 0 },
      prijavljenoStanje: { avt: 0, amt: 0, alreadyAccepted: 0 },
      prihvacenoNovoStanje: false,
      postojiPrijavljenoStanje: false,
      isLoaded: false,
      numRows: 0
    }
  }

  getMjernaMjesta = () => {
    let customerNumber = sessionStorage.getItem("naplatniBroj")
    fetch(MJERNA_MJESTA_ALL_URL(customerNumber), {
      method: "GET",
      headers: authenticationService.authenticationHeader()
    })
      .then(results => results.json())
      .then(data => {
        this.setState({
          mjernaMjesta: data,
          mjernoMjestoId: data[0].id,
          numRows: data.length
        })
        this.getPoslednjaOcitanaStanja(data[0].id);
        this.isPrijavljenoNovoStanje(data[0].id);
        this.getPrijavljenoStanje(data[0].id);
      });
  }

  getPoslednjaOcitanaStanja = (mjernoMjestoId) => {
    let customerNumber = sessionStorage.getItem("naplatniBroj")
    if (this.state.mjernoMjestoId === 0) {
      return;
    }
    fetch(OCITANJA_LAST_URL(customerNumber, mjernoMjestoId), {
      method: "GET",
      headers: authenticationService.authenticationHeader()
    }).then(results => results.json())
      .then(data => {
        this.setState({
          ocitanoStanje: data,
          isLoaded: true
        })
      });
  }

  isPrijavljenoNovoStanje = (mjernoMjestoId) => {
    let customerNumber = sessionStorage.getItem("naplatniBroj")
    if (this.state.mjernoMjestoId === 0) {
      return;
    }
    fetch(IS_PRIJAVLJENO_NOVO_STANJE_URL(customerNumber, mjernoMjestoId), {
      method: "HEAD",
      headers: authenticationService.authenticationHeader()
    }).then(results => {
      if (results.status === 200) {
        this.setState({
          postojiPrijavljenoStanje: true
        })
      } else {
        this.setState({
          postojiPrijavljenoStanje: false
        })
      }
    })
  }

  getPrijavljenoStanje(mjernoMjestoId) {
    let customerNumber = sessionStorage.getItem("naplatniBroj")
    if (this.props.mjernoMjestoId === 0) {
      return;
    }
    fetch(GET_PRIJAVLJENO_STANJE_URL(customerNumber, mjernoMjestoId), {
      method: "GET",
      headers: authenticationService.authenticationHeader()
    })
      .then(results => results.json())
      .then(data => {
        this.setState({
          prijavljenoStanje: data
        })
      });
  }

  selectedMjernoMjesto = (event) => {
    this.setState({
      mjernoMjestoId: event.target.value
    })
    let mjernoMjestoId = event.target.value;
    this.getPoslednjaOcitanaStanja(mjernoMjestoId);
    this.isPrijavljenoNovoStanje(mjernoMjestoId);
    this.getPrijavljenoStanje(mjernoMjestoId);
  }

  componentDidMount() {
    let userId = authenticationService.getUser().id;
    this.setState({
      userId: userId
    });
    this.getMjernaMjesta();
  }

  render() {
    if (this.state.isLoaded) {
    return (
      <div className="container-fluid">
        <h4 className="titlePrijavaStanja">{t`prijavaStanja`}</h4><br></br>
          <Col>
            <Row>
              <label className="mt-1 col-auto">{t`mjernoMjesto`}:</label>
              <div className="col-12 col-md-12 col-xl-5">
                <Input type='select' disabled={this.state.numRows > 1 ? false : true} onChange={this.selectedMjernoMjesto} className="text form-control-sm" id="mjerno-mjesto">
                  {this.state.mjernaMjesta.map(mm => {
                    return <option className="text" key={mm.id} value={mm.id}>{mm.address}</option>
                  })}
                </Input>            
              </div>
            </Row>
            <Row className="pt-3">
                <div>           
                  <label className="w-50 ml-3 d-inline mr-3">{t`brojBrojila`}:</label>
                  <label className="text">{this.state.ocitanoStanje.activeMeterNumber}</label>
                </div>
            </Row>
          </Col><br></br>
        <PrijavaStanjaForma postojiPrijavljenoStanje={this.state.postojiPrijavljenoStanje} prihvacenoNovoStanje={this.state.prihvacenoNovoStanje} prijavljenoStanje={this.state.prijavljenoStanje} ocitanoStanje={this.state.ocitanoStanje} userId={this.state.userId} mjernoMjestoId={this.state.mjernoMjestoId}></PrijavaStanjaForma>      
      </div>
    );
  }
  return (
    <Spinner className="payment-spiner" color="primary" />
  );
  }
}