import React, { Component } from 'react'
import { Row } from 'reactstrap';
import MainBody from './MainBody'

export class MainContent extends Component {
    render() {
        return (
            <Row className="main-main-content main-no-margin">
                <MainBody />
            </Row>
        )
    }
}

export default MainContent
