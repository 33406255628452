import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Input } from 'reactstrap';
import { t } from 'ttag';
import './ResetPassword.css';
import { authenticationService } from '../authentication/AuthenticationService';


export default class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email:'',
      showMessage: false,
      actionSuccess: false,
      messageReset: ' ',
    }
    this.sendAction = this.sendAction.bind(this)
    this.handleBtnNazad = this.handleBtnNazad.bind(this)
  }
  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }
  sendAction = e => {
    this.setState({ showMessage: true })
    this.forgotPassword();
  }
  handleBtnNazad = e => {
    if(this.state.actionSuccess){
      this.props.showLoginDialog();
    }else{
      this.setState({ showMessage: false })
    }
  }
  forgotPassword = e => {
    const { email } = this.state
    this.setState({
      messageReset: ''
    })
    authenticationService.resetPassword(email).then(userStatus => {
      if(!userStatus.success){
        console.log(userStatus.error);
        this.setState({
          messageReset: userStatus.error,
          actionSuccess: false
        })
      } else {
        console.log(userStatus.error);
        this.setState({
          messageReset: t`dostavljenaNovaLozinka`,
          actionSuccess:true
        })
      }
    })
  }
  render() {
    return (
      <div>
        {this.state.showMessage ? (
          <div>
            <h1 className='text text-dark'>
             {this.state.messageReset}
                </h1>
            <button
              className='btn submit-button'
              onClick={this.handleBtnNazad}
            >{t`nazad`}</button>
          </div>
        ) : (
            <div>
  <h1 style={{color:'black', fontSize:'24px'}}>{t`zaboraviliSteLozinku`}</h1>
              <h1 className='text text-dark'>{t`textInstrukcijeZaLozinku`}</h1>
              <div className="row col-12 nopadding d-flex justify-content-between pb-4">
                <Input
                  className="fieldsLine"
                  placeholder={t`email`}
                  type='email'
                  name='email'
                  value={this.state.email}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <Row className='p-xs-0 p-xl-12'>
                <Col>
                  <button
                    className='btn submit-button'
                    onClick={this.props.showLoginDialog}
                  >{t`nazad`}</button>
                </Col>
                <Col>
                  <button
                    className='btn submit-button  float-right'
                    onClick={this.sendAction}
                  >{t`posalji`}</button>
                </Col>
              </Row>
            </div>
          )}

      </div>
    )
  }
}
