import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import SignUpView from './route_login/SignUpView';
import LoginView from './route_login/LoginView';
import { ProtectedRoute } from './authentication/ProtectedRoute';
import MainView from './main/MainView';


export default class App extends React.Component {
 
  render() {
    return (
    
        <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''}>
          <Switch>
            <Route path="/login" component={LoginView} />
            <ProtectedRoute path="/" component={MainView} />
          </Switch>
        </BrowserRouter>
      

    );
  }
};
