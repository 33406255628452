import React, { Component } from 'react';
import "./custombutton.css"

class CustomButton extends Component {
    render() {
        let iconClass = "custom-button-icon lnc ".concat(this.props.icon)

        let buttonClass;
        if (this.props.dark) {
            buttonClass = "custom-button custom-button-dark";
        } else {
            buttonClass = "custom-button custom-button-normal";
        }
        if(this.props.disabled){
            buttonClass = "custom-button custom-button-normal custom-button-disabled"
        }

        return (
            <button style={this.props.style} type="button" className={buttonClass} onClick={this.props.onClick} disabled={this.props.disabled}><i className={iconClass}></i>{this.props.text}</button >
        );
    }
}

export default CustomButton;